import React, { useState, useEffect } from "react"
import OrganizationsApi from "../../../services/organizations";
import Page from "../../Page"
import UnpublishedOrganizationsTable from "../../../tables/UnpublishedOrganizationsTable"
// import DashboardHeader from '../../../components/DashboardHeader';
import { PopupboxContainer } from 'react-popupbox';

function OrganizationsPage(props) {
    const [organizations, setOrganizations] = useState([]);
    const organizationsApi = new OrganizationsApi(props.token);
    const [search, setSearch] = useState('');
    const [searchCity, setSearchCity] = useState('');

    const fetch = () => {
        organizationsApi.searchUnpublishedOrganization("Unpublished").then((response) => {
            setOrganizations(response);
        })
    }

    useEffect(()=>{
        if(search && searchCity){
            organizationsApi.searchUnpublishedOrganizationByCity(searchCity, "Unpublished").then((response) => {
                let filteredData = response.filter((org) => org.name === search)
                setOrganizations([...filteredData]);
            }).catch((error) => {
                console.error(error);
                setOrganizations([]);
            });
        }else if(search && !searchCity){
            let search_results = organizations.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));
            setOrganizations([...search_results]);
        }else if(!search && searchCity){
            organizationsApi.searchUnpublishedOrganizationByCity(searchCity, "Unpublished").then((response) => {
                setOrganizations(response);
            }).catch((error) => {
                console.error(error);
                setOrganizations([]);
            });
        }else{
            fetch()
        }
    },[search, searchCity])

    // Search
    const __handleSearch = (event) => {
        setSearch(event.target.value);
        // if (event.target.value !== '') {
        //     let search_results = organizations.filter((item) => item.name.toLowerCase().includes(event.target.value.toLowerCase())
        //     );
        //     setOrganizations([...search_results]);
        // }
        // else {
        //     fetch()
        // }
    };

    const handleSearchByCity =(event)=>{
        let value = event.target.value;
        setSearchCity(value);
    }
  
    return (
        <div className='dashboard-content'>
            {/* <DashboardHeader
                btnText="New Organization" link='/organization/new' /> */}
            <div className='dashboard-content-container'>
                <div className='dashboard-content-header'>
                    <h2>Unpublished Organizations</h2>
                    <div>
                        <div className='dashboard-content-search'>
                            <input
                                type='text'
                                value={search}
                                placeholder='Search by name..'
                                className='dashboard-content-input'
                                onChange={e => __handleSearch(e)} />
                        </div>
                        <div className='dashboard-content-search search-city-input'>
                            <input
                                type='text'
                                value={searchCity}
                                placeholder='Search by city..'
                                className='dashboard-content-input'
                                onChange={e => handleSearchByCity(e)} />
                        </div>
                    </div>
                </div>
                <div className="organizations-custom-popup">
                    <PopupboxContainer />
                </div>
                    {
                        organizations.length > 0 ?
                            <UnpublishedOrganizationsTable organizations={organizations}  {...props} refresh={fetch} />
                            :
                            <div className='dashboard-content-footer'>
                                <span className='empty-table'>No data</span>
                            </div>
                    }
               
            </div>
        </div>
    )
}

export default OrganizationsPage