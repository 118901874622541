import React, { useState, useEffect } from "react"
import CountryApi from "../../services/countries";
import CountryTable from "../../tables/MunicipleHierarchy/CountryTable"
import DashboardHeader from '../../components/DashboardHeader';
import { PopupboxContainer } from 'react-popupbox';
import Pagination from 'react-bootstrap/Pagination';


function CountriesPage(props) {
    const [countries, setCountries] = useState([]);
    const countryApi = new CountryApi(props.token);
    const [search, setSearch] = useState('');
    const [totalPages, setTotalPages] = useState(0);
    const [pageItemActive, setPageItemActive] = useState(1);

    const fetch = (pageItemActive) => {
        countryApi.all(pageItemActive).then((response) => {
            setTotalPages(response.total_pages)
            setCountries([...response.countries])
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
    }

    useEffect(()=>{
        if (search) {
            countryApi.search(search,pageItemActive).then((response) => {
                setTotalPages(response.total_pages)
                setCountries([...response.countries])
            })
            .catch((err) => {
                console.error(err.message, err.stack);
            });
        }else{
            fetch(pageItemActive)
        }
    },[search, pageItemActive])

    const __handleSearch = (event) => {
        setSearch(event.target.value);
    };

    const handleSelectItem = (pageNumber)=>{
        setPageItemActive(pageNumber);
    }

    const getPaginationItems =()=>{
        let items = [];
        for (let number = 1; number <= totalPages; number++) {
            items.push(
                <Pagination.Item key={number} active={number === pageItemActive} onClick={()=>handleSelectItem(number)}>
                    {number}
                </Pagination.Item>,
            );
        }
        return items;
    }

    const previousPage = async()=>{
        let currentPageNumber = pageItemActive > 1 ? pageItemActive - 1 : pageItemActive
        setPageItemActive(currentPageNumber);
    }

    const nextPage = async()=>{
        let currentPageNumber = pageItemActive < totalPages ? pageItemActive + 1 : totalPages
        setPageItemActive(currentPageNumber);
    }

    const items = getPaginationItems()
   
    return (
        <div className='dashboard-content'>
            <DashboardHeader
                btnText="New Country" link='/country/new' />
            <div className='dashboard-content-container'>
                <div className='dashboard-content-header'>
                    <h2>Countries</h2>
                    <div>
                        <div className='dashboard-content-search'>
                            <input
                                type='text'
                                value={search}
                                placeholder='Search by name..'
                                className='dashboard-content-input'
                                onChange={e => __handleSearch(e)} />
                        </div>
                    </div>
                </div>
                <div className="organizations-custom-popup">
                    <PopupboxContainer />
                </div>
                    {
                        countries.length > 0 ?
                        <div>
                            <CountryTable countries={countries} {...props} refresh={fetch} />
                            {
                                totalPages > 1 && <div className="events-pagination-wrap">
                                    <Pagination size="sm">
                                        <Pagination.Prev onClick={() => previousPage()} />
                                        {items}
                                        <Pagination.Next onClick={() => nextPage()} />
                                    </Pagination>
                                </div>
                            }
                         </div>
                            :
                            <div className='dashboard-content-footer'>
                                <span className='empty-table'>No data</span>
                            </div>
                    }
               
            </div>
        </div>
    )
}

export default CountriesPage