
import {useParams, useNavigate} from "react-router-dom";
import React, {useState, useEffect} from 'react';
import Page from "../Page";
import CreateSponsorForm from "../../forms/CreateSponsorForm"
import OrganizationApi from "../../services/organizations";
import SponsorApi from "../../services/sponsorships";

import Error from "../../fragments/form/Error"
import Message from "../../fragments/form/Message"

function CreateSponsorPage(props) {
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [organizations, setOrganizations] = useState([]);
    const organizationApi = new OrganizationApi(props.token);
    const sponsorApi = new SponsorApi(props.token);

    const { organizationId, eventId } = useParams();
    const navigate = useNavigate();
    
    useEffect(() => {
        organizationApi.all().then((organizations) => {
            setOrganizations([...organizations])
        })
        .catch((err) => {
            console.error(err.message);
        });
    }, []);


    const onSubmit = (formData) => {
        let formatValue = parseInt(eventId)
        formData.set("event_id", formatValue);
        sponsorApi.create(formData).then((response) => {
            navigate(`/organization/${organizationId}/event/${eventId}/sponsors`);
        })
        .catch((err) => {
            console.error(err.message);
            setError(err.message)
            setMessage("");
        });
    }


    return (
        <Page
            buttonText="Back"
            buttonUrl={`/organization/${organizationId}/event/${eventId}/sponsors`}
            header={`Create Sponsor`}
        >
            <Error error={error} />
            <Message message={message} />
            <CreateSponsorForm
                {...props}
                onSubmit={onSubmit}
                organizations={organizations}
                />
        </Page>
    );
}

export default CreateSponsorPage;