import API from './api'

const {REACT_APP_API_URI} = process.env;


const updateProperty = (subject, keyProperty, valueProperty, collection, defaultValue) => {
    if(typeof defaultValue == "undefined") {
        defaultValue = null;
    }
    const key = subject[keyProperty];
    if(collection[key]) {
        subject[valueProperty] = collection[key]
    } else {
        subject[valueProperty] = defaultValue;
    }
    return subject
}

const formatEvents = (response) => {
    let events = Object.values(response.data.data.events);
    const users = response.data.data.users;
    const images = response.data.data.images;
    const locations = response.data.data.locations;
    const organizations = response.data.data.organizations;
    const categories = response.data.data.categories;
    return events.map((event) => {
        event = updateProperty(event, "created_by", "created_by", users);
        event = updateProperty(event, "updated_by", "updated_by", users);
        event = updateProperty(event, "deleted_by", "deleted_by", users);
        event = updateProperty(event, "location_id", "location", locations);
        event = updateProperty(event, "organization_id", "organization", organizations);
        event = updateProperty(event, "category_id", "category", categories);
        event = updateProperty(event, "profile_image_id", "profile_image", images);
        event = updateProperty(event, "card_image_id", "card_image", images);
        return event;
    });
}

const formatEvent = (response) => {
    let event = response.data.data.event;
    const users = response.data.data.users;
    const images = response.data.data.images;
    const location = response.data.data.location;

    event = updateProperty(event, "created_by", "created_by", users);
    event = updateProperty(event, "updated_by", "updated_by", users);
    event = updateProperty(event, "deleted_by", "deleted_by", users);
    event.location = response.data.data.location;
    event.card_image = images.card
    event.profile_image = images.profile
    return event
}

class EventsApi {
    constructor(token) {
        this.api = new API(token);
        this.baseUrl = `${REACT_APP_API_URI}/admin/event`
    }

    search() {
        try {
            return this.api.get(this.baseUrl)
            .then((response) => {
                return formatEvents(response)
            });
        }catch(error) {
            console.error(error);
            return []
        }
    }

    getByOrganizationId(organizationId) {
        try {
            return this.api.get(`${REACT_APP_API_URI}/admin/organization/${organizationId}/events`)
            .then((response) => {
                return formatEvents(response)
            });
        }catch(error) {
            console.error(error);
            return []
        }
    }

    getByOrganizationIdPerPage(organizationId,pageNumber) {
        try {
            return this.api.get(`${REACT_APP_API_URI}/admin/organization/${organizationId}/events?page_num=${pageNumber}`)
            .then((response) => {
                let events =  formatEvents(response);
                let total_pages = response.data.data.total_pages;
                let per_page_items = response.data.data.per_page_items;
                return{
                    events: events,
                    total_pages: total_pages,
                    per_page_items: per_page_items
                }
            });
        }catch(error) {
            console.error(error);
            return []
        }
    }

    getById(id) {
        try {
            return this.api.get(`${this.baseUrl}/${id}`)
            .then((response) => {
                return formatEvent(response);
            });
        } catch(error) {
            console.error(`Error fetching event. id=${id}, error=${error}`);
            return {}
        }
    }

    update(id, body) {
        try{
            return this.api.patch(`${this.baseUrl}/${id}`, body, "multipart/form-data")
            .then((response) => {
                return formatEvent(response);
            });
        }catch(error) {
            console.error(`Error updating event. id=${id}, error=${error}`);
            return {}
        }
    }

    create(body) {
        try{
            return this.api.post(this.baseUrl, body, "multipart/form-data")
            .then((response) => {
                return response.data.data.event;
            });
        }catch(error) {
            console.error(`Error creating event. error=${error}`);
            return {}
        }
    }

    delete(id) {
        try{
            return this.api.delete(`${this.baseUrl}/${id}`)
            .then((response) => {
                return response.data.data;
            });
        }catch(error) {
            console.error(`Error deleting event. error=${error}`);
            return {}
        }
    }
}

export default EventsApi;