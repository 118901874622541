import React, {useState, useEffect} from "react"
import EventApi from "../../services/events";
import {useParams} from "react-router-dom";

import Page from "../Page"
import EventPanel from "../../panels/EventPanel"
import Loading from "../../fragments/Loading"
import EventParticipantsHeaderNav from "../../fragments/nav/SponsorshipsHeaderNav"


function OrganizationEventPage(props) {
    const [event, setEvent] = useState(null);
    const eventApi = new EventApi(props.token);
    const { organizationId, eventId } = useParams();

    useEffect(() => {
        eventApi.getById(eventId).then((response) => {
            setEvent(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
    },[]);

    if(!event) {
        return (<Loading />)
    }
    return(
        <Page 
            buttonText="Back"
            buttonUrl={`/organization/${organizationId}/events`}
            header={`Event: ${event.title}`}
        >
            <EventParticipantsHeaderNav organizationId={organizationId} eventId={eventId} />
            <EventPanel event={event} {...props} />
        </Page>
    )
}

export default OrganizationEventPage;