
import {useState} from 'react';
import DropdownSelector from "../fragments/form/DropdownSelector"
import SubmitButton from "../fragments/form/SubmitButton"


function CreateSponsorForm(props) {
    const [formData, setFormData] = useState(new FormData());

    const onChange = (id, value) => {
        let formatValue = parseInt(value)
        formData.set(id, formatValue);
        setFormData(formData);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        props.onSubmit(formData)
    }

    return (
        <form onSubmit={onSubmit}>
            <DropdownSelector id="sponsor_id" required={true} collection={props.organizations} onChange={onChange} label="Sponsor" prompt="Please select a sponsor"  onLoad={() => onChange("sponsor_id", 0)} />
            <SubmitButton />
        </form>
    );
}

export default CreateSponsorForm;