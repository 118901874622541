import React, {useState, useEffect} from "react"
import {useParams, useNavigate} from "react-router-dom";

import OrganizationApi from "../../services/organizations";
import LocationApi from "../../services/locations";
import OrganizationHeaderNav from "../../fragments/nav/OrganizationHeaderNav"
import EditLocationForm from "../../forms/EditLocationForm"
import CountryApi from "../../services/countries";
import StateApi from "../../services/states";
import MunicipalityApi from "../../services/municipalities";

import Page from "../Page"
import Loading from "../../fragments/Loading"
import Error from "../../fragments/form/Error"
import Message from "../../fragments/form/Message"

function EditOrganizationLocationPage(props) {
    const [error, setError] = useState();
    const [message, setMessage] = useState();
    const [location, setLocation] = useState();
    const [organization, setOrganization] = useState();
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([])
    const [countryId, setCountryId] = useState(0);
    const [stateId, setStateId] = useState(0);
    const [cityId, setCityId] = useState(0);

    const locationApi = new LocationApi(props.token);
    const organizationApi = new OrganizationApi(props.token);
    const countryApi = new CountryApi(props.token);
    const stateApi = new StateApi(props.token);
    const municipalityApi = new MunicipalityApi(props.token);

    const { organizationId, locationId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        organizationApi.getById(organizationId).then((response) => {
            setOrganization(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
        locationApi.getById(locationId).then((response) => {
            setLocation(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
        countryApi.getlist(100).then((response) => {
            setCountries([...response]);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
    },[]);


    const findCountryStateAndCity = async (location) => {
        try {
            if (location && location.country) {
                let countries = await countryApi.getCountriesByName(location.country)
                let countryData = countries.find((e) => e.country === location.country)
                if (countryData) {
                    setCountryId(countryData.id)
                    let states = await stateApi.getStatesByName(countryData.id, location.state)
                    let stateData = states.find((e) => e.state === location.state)
                    if (stateData) {
                        setStateId(stateData.id)
                        let cities = await municipalityApi.getCitiesByName(stateData.id, location.city)
                        let cityData = cities.find((e) => e.city === location.city)
                        if (cityData) {
                            setCityId(cityData.id)
                        } else {
                            setCityId(0)
                        }
                    } else {
                        setStateId(0)
                    }
                } else {
                    setCountryId(0)
                }
            } else {
                setCountryId(0)
                setStateId(0)
                setCityId(0)
            }
        } catch (err) {
            console.error(err.message);
        }
    }

    const fetchStatesByCountryId = async(countryId)=>{
        try {
            if(countryId){
                let states = await stateApi.getlist(countryId, 100)
                setStates([...states])
            }else{
                setStates([])
            }
        } catch (err) {
            console.error(err.message);
        }
    }

    const fetchCitiesByStateId = async(stateId)=>{
        try {
            if(stateId){
                let cities = await municipalityApi.getlist(stateId, 100)
                setCities([...cities])
            }else{
                setStates([])
            }
        } catch (err) {
            console.error(err.message);
        }
    }

    useEffect(()=>{
       findCountryStateAndCity(location)
    },[location])

    useEffect(()=>{
        fetchStatesByCountryId(countryId)
        fetchCitiesByStateId(stateId)
    },[countryId, stateId])

    const handleSetCountryId=(id)=>{
        setCountryId(id)
    }

    const handleSetStateId=(id)=>{
        setStateId(id)
    }

    const handleSetCityId=(id)=>{
        setCityId(id)
    }

    const onSubmit = (formData) => {
        locationApi.update(locationId, formData).then((response) => {
            navigate(`/organization/${organizationId}/locations`);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
            setError(err.message)
            setMessage("");
        });
    }
    if(!organization || !location) {
        return (<Loading />)
    }
    return(
        <Page 
            buttonText="Back"
            buttonUrl={`/organization/${organizationId}/locations`}
            header={`Viewing location "${location.name}" for organization: ${organization.name}`}
        >
            <OrganizationHeaderNav organizationId={organizationId} />
            <Error message={error} />
            <Message message={message} />
            <EditLocationForm
                location={location}
                organization={organization}
                countries={countries}
                states={states}
                cities={cities}
                countryId={countryId}
                stateId={stateId}
                cityId={cityId}
                handleSetCountryId={handleSetCountryId}
                handleSetStateId={handleSetStateId}
                handleSetCityId={handleSetCityId}
                onSubmit={onSubmit}
            />
        </Page>
    )
}

export default EditOrganizationLocationPage;