import React, {useState, useEffect} from "react"
import {useParams} from "react-router-dom";

import OrganizationApi from "../../services/organizations";
import LocationApi from "../../services/locations";

import LocationsTable from "../../tables/LocationsTable"
import OrganizationHeaderNav from "../../fragments/nav/OrganizationHeaderNav"

import Page from "../Page"
import Loading from "../../fragments/Loading"

import Error from "../../fragments/form/Error"
import Message from "../../fragments/form/Message"

function OrganizationLocationsPage(props) {
    const [locations, setLocations] = useState([]);
    const [organization, setOrganization] = useState();
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);

    const locationApi = new LocationApi(props.token);
    const organizationApi = new OrganizationApi(props.token);

    const { organizationId } = useParams();

    useEffect(() => {
        organizationApi.getById(organizationId).then((response) => {
            setOrganization(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
        fetch();
    },[]);

    const fetch = () => {
        locationApi.getByOrganizationId(organizationId).then((response) => {
            setLocations(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
    }

    const onDelete = (id, name) => {
        locationApi.delete(id).then((response) => {
            setMessage(`Location "${name}" has been deleted`)
            setError("")
            fetch();
        })
        .catch((err) => {
            console.error(err.message);
            setError(err.message)
            setMessage("")
        });
    }

    if(!organization) {
        return (<Loading />)
    }
    return(
        <Page 
            buttonText="Create Location"
            buttonUrl={`/organization/${organizationId}/location/new`}
            header={`Organization: ${organization.name}`}
        >
            <OrganizationHeaderNav organizationId={organizationId} />
            <Error message={error} />
            <Message message={message} />
            <LocationsTable
                locations={locations}
                organization={organization}
                onDelete={onDelete}
                {...props} />
        </Page>
    )
}

export default OrganizationLocationsPage;