import {useState, useEffect} from "react";

import TextField from "../fragments/form/TextField"
import FloatField from "../fragments/form/FloatField"
import DropdownSelector from "../fragments/form/DropdownSelector"

import TextLabel from "../fragments/form/TextLabel"
import CheckboxSelector from "../fragments/form/CheckboxSelector"
import SubmitButton from "../fragments/form/SubmitButton"

const EditLocationForm = (props) => {
    const [formData, setFormData] = useState({});

    const onChange = (id, value) => {
        formData[id] = value;
        if(id === 'country'){
            props.handleSetCountryId(value)
            props.handleSetStateId(0) 
            props.handleSetCityId(0)
            let countryData = props.countries.find((e) => e.id === parseInt(value))
            let countryValue = countryData ? countryData.country : '';
            formData[id] = countryValue
        }
        if(id === 'state'){
            props.handleSetStateId(value) 
            props.handleSetCityId(0)
            let stateData = props.states.find((e) => e.id === parseInt(value))
            let stateValue = stateData ? stateData.state : '';
            formData[id] = stateValue
        }
        if(id === 'city'){
            let cityData = props.cities.find((e) => e.id === parseInt(value))
            let cityValue = cityData ? cityData.city : '';
            formData[id] = cityValue
        }
        setFormData(formData);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        props.onSubmit(formData);
    }

    return (
        <form onSubmit={onSubmit}>
            <TextLabel
                label="ID"
                value={props.location.id}
            />
            <TextField
                id="name"
                onChange={onChange}
                label="Name"
                required={true}
                value={props.location.name}
            />
            <TextField
                id="address_1"
                onChange={onChange}
                label="Address1"
                required={true}
                value={props.location.address_1}
            />
            <TextField
                id="address_2"
                onChange={onChange}
                label="Address2"
                required={false}
                value={props.location.address_2}
            />
            <DropdownSelector id="country"  selectedId={props.countryId}  required={true} collection={props.countries} onChange={onChange} label="Country" prompt="Please select a country"  onLoad={() => onChange("country", '')} />
            <DropdownSelector id="state"  selectedId={props.stateId}  required={true} collection={props.states} onChange={onChange} label="State" prompt="Please select a country before select a state"  onLoad={() => onChange("state", '')} />
            <DropdownSelector id="city"  selectedId={props.cityId} required={true} collection={props.cities} onChange={onChange} label="City" prompt="Please select a state before select a city"  onLoad={() => onChange("city", '')} />
            {/* <TextField
                id="city"
                onChange={onChange}
                label="City"
                required={true}
                value={props.location.city}
            />
            <TextField
                id="state"
                onChange={onChange}
                label="State"
                value={props.location.state}
            /> */}
            <TextField
                id="zip"
                onChange={onChange}
                label="Zip"
                required={true}
                value={props.location.zip}
            />
            {/* <TextField
                id="country"
                onChange={onChange}
                label="Country"
                required={true}
                value={props.location.country}
            /> */}
            <FloatField
                id="longitude"
                onChange={onChange}
                label="Longitude"
                required={true}
                value={props.location.longitude}
            />
            <FloatField
                id="latitude"
                onChange={onChange}
                label="Latitude"
                required={true}
                value={props.location.latitude}
            />
            <CheckboxSelector
                id="is_published"
                onChange={onChange}
                label="Is Published?"
                value={props.location.is_published}
            />
            <SubmitButton />
        </form>
    )
}

export default EditLocationForm;