import React, {useState, useEffect} from "react"
import OrganizationApi from "../../services/organizations";
import {useParams} from "react-router-dom";

import Page from "../Page"
import OrganizationPanel from "../../panels/OrganizationPanel"
import Loading from "../../fragments/Loading"


function SponsorPage(props) {
    const [sponsor, setSponsor] = useState(null);
    const organizationApi = new OrganizationApi(props.token);
    const { organizationId, eventId, sponsorId } = useParams();

    const fetch = () => {
        organizationApi.getById(sponsorId).then((response) => {
            setSponsor(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
    }

    useEffect(() => {
        fetch()
    }, []);

    if(!sponsor) {
        return (<Loading />)
    }

    return(
        <Page 
            buttonText="Back"
            buttonUrl={`/organization/${organizationId}/event/${eventId}/sponsors`}
            header={`Sponsor: ${sponsor? sponsor.name: ""}`}
        >
            <OrganizationPanel organization={sponsor} {...props} />
        </Page>
    )
}

export default SponsorPage;