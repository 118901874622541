
import FormGroup from "./FormGroup"
import {useState, useEffect} from 'react';


const DropdownSelector = (props) => {
    const [data, setData] = useState(props.value);

    const onChange = (e) => {
        setData(e.target.value);
        props.onChange(props.id, e.target.value);
    }

    useEffect(() => {
        if(props.onLoad) {
            props.onLoad();
        }
    },[])
    if(!props.collection) {
        return (<select />)
    }
   
    return (
        <FormGroup {...props} label={props.label}>
            <select required className="form-select"
                    name={props.id}
                    onChange={onChange}
                    value={data}
            >
                <option>{props.prompt}</option>
                {props.collection ? props.collection.map((item, index) => (
                    <option value={item.id} key={index} selected={props.selectedId == item.id}>
                        {props.id === 'country' ? item.country : props.id === 'state' ? item.state : props.id === 'city' ? item.city : item.name}
                    </option>
                )) : null}
            </select>
        </FormGroup>
    )
}

export default DropdownSelector;