
import {useParams, useNavigate} from "react-router-dom";
import React, {useState, useEffect} from 'react';
import Page from "../Page";
import CreateEventParticipantForm from "../../forms/CreateEventParticipantForm"
import OrganizationApi from "../../services/organizations";
import ParticipantApi from "../../services/participants";
import ParticipantRoleApi from "../../services/participantRole";

import Error from "../../fragments/form/Error"
import Message from "../../fragments/form/Message"

function CreateParticipantPage(props) {
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [organizations, setOrganizations] = useState([]);
    const [participantRoles, setParticipantRoles] = useState([]);
    const organizationApi = new OrganizationApi(props.token);
    const participantApi = new ParticipantApi(props.token);
    const participantRoleApi = new ParticipantRoleApi(props.token)

    const { organizationId, eventId } = useParams();
    const navigate = useNavigate();

    
    useEffect(() => {
        organizationApi.all().then((organizations) => {
            setOrganizations([...organizations])
        })
        .catch((err) => {
            console.error(err.message);
        });

        participantRoleApi.getRoles().then((participantRoles) => {
            setParticipantRoles([...participantRoles])
        })
        .catch((err) => {
            console.error(err.message);
        });
    }, []);


    const onSubmit = (formData) => {
        let formatValue = parseInt(eventId)
        formData.set("event_id", formatValue);
        participantApi.create(formData).then((response) => {
            navigate(`/organization/${organizationId}/event/${eventId}/participants`);
        })
        .catch((err) => {
            console.error(err.message);
            setError(err.message)
            setMessage("");
        });
    }

    return (
        <Page
            buttonText="Back"
            buttonUrl={`/organization/${organizationId}/event/${eventId}/participants`}
            header={`Create Participant`}
        >
            <Error error={error} />
            <Message message={message} />
            <CreateEventParticipantForm
                {...props}
                onSubmit={onSubmit}
                organizations={organizations}
                participantRoles={participantRoles}
                />
        </Page>
    );
}

export default CreateParticipantPage;