
import {useParams, useNavigate} from "react-router-dom";
import React, {useState, useEffect} from 'react';
import Page from "../Page";
import CreateOrganizationForm from "../../forms/CreateOrganizationForm"
import OrganizationApi from "../../services/organizations";
import CategoryApi from "../../services/categories";
import LocationApi from "../../services/locations";
import CountryApi from "../../services/countries";
import StateApi from "../../services/states";
import MunicipalityApi from "../../services/municipalities";

import Error from "../../fragments/form/Error"
import Message from "../../fragments/form/Message"

function CreateOrganizationPage(props) {
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [categories, setCategories] = useState();
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([])
    const [countryId, setCountryId] = useState(0);
    const [stateId, setStateId] = useState();
    
    const categoryApi = new CategoryApi(props.token);
    const organizationApi = new OrganizationApi(props.token);
    const locationApi = new LocationApi(props.token);
    const countryApi = new CountryApi(props.token);
    const stateApi = new StateApi(props.token);
    const municipalityApi = new MunicipalityApi(props.token);

    const { organizationId } = useParams();
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            let categories = await categoryApi.all();
            setCategories(Object.values(categories).filter(category => category.is_organization))
            let countries = await countryApi.getlist(100);
            setCountries([...countries]) 
        } catch (err) {
            console.error(err.message);
        }
    }

    useEffect(() => {
        // categoryApi.all().then((categories) => {
        //     setCategories(Object.values(categories).filter(category => category.is_organization));
        // })
        // .catch((err) => {
        //     console.error(err.message);
        // });
          fetchData()
    }, []);

    const fetchStatesByCountryId = async(countryId)=>{
        try {
            if(countryId){
                let states = await stateApi.getlist(countryId, 100)
                setStates([...states])
            }else{
                setStates([])
            }
        } catch (err) {
            console.error(err.message);
        }
    }

    const fetchCitiesByStateId = async(stateId)=>{
        try {
            if(stateId){
                let cities = await municipalityApi.getlist(stateId, 100)
                setCities([...cities])
            }else{
                setStates([])
            }
        } catch (err) {
            console.error(err.message);
        }
    }

    useEffect(()=>{
        fetchStatesByCountryId(countryId)
        fetchCitiesByStateId(stateId)
    },[countryId, stateId])

    const handleSetCountryId=(id)=>{
        setCountryId(id)
    }

    const handleSetStateId=(id)=>{
        setStateId(id)
    }

    const onSubmit = (formData, locationFormData) => {
        organizationApi.create(formData).then((response) => {
            let organizationItemData = locationFormData
            organizationItemData.set("organization_id",response.id)
            organizationItemData.set("name",formData.get("name"))
            organizationItemData.set("is_published",formData.get("is_published"))
              if(organizationItemData.entries().next().value){
                locationApi.create(organizationItemData).then((locationResponse) => {
                    formData.set("location_id",locationResponse.id)
                    organizationApi.update(response.id, formData).then((result) => {
                        navigate(`/organization/${response.id}`);
                    }) .catch((err) => {
                        console.error(err.message, err.stack);
                        setError(err.message)
                        setMessage("");
                    });
                })
                .catch((err) => {
                    console.error(err.message, err.stack);
                    setError(err.message)
                    setMessage("");
                });
              }else{
                navigate(`/organization/${response.id}`);
              }
        })
        .catch((err) => {
            console.error(err.message, err.stack);
            setError(err.message)
            setMessage("");
        });
    }


    return (
        <Page
            buttonText="Back"
            buttonUrl="/organizations"
            header={`Create Organization`}
        >
            <Error error={error} />
            <Message message={message} />
            <CreateOrganizationForm
                {...props}
                onSubmit={onSubmit}
                categories={categories}
                countries={countries}
                states={states}
                cities={cities}
                handleSetCountryId={handleSetCountryId}
                handleSetStateId={handleSetStateId}
                />
        </Page>
    );
}

export default CreateOrganizationPage;