import React, { useState, useEffect } from "react"
import StateApi from "../../services/states";
import CountryApi from "../../services/countries";
import StateTable from "../../tables/MunicipleHierarchy/StateTable"
import DashboardHeader from '../../components/DashboardHeader';
import { PopupboxContainer } from 'react-popupbox';
import Pagination from 'react-bootstrap/Pagination';
import { useParams } from "react-router-dom";

function StatesPage(props) {
    const [states, setStates] = useState([]);
    const [countryName, setCountryName] = useState('')
    const stateApi = new StateApi(props.token);
    const [search, setSearch] = useState('');
    const [totalPages, setTotalPages] = useState(0);
    const [pageItemActive, setPageItemActive] = useState(1);
    const countryApi = new CountryApi(props.token)

    const {countryId} = useParams()

    const fetch = async(countryId,pageItemActive) => {
        try {
            let response = await  stateApi.all(countryId, pageItemActive)
            let countryData = response.countries.find((e)=> e.id === parseInt(countryId));
            if(countryData){
                setCountryName(countryData.country)
            }else{
                let countryResponse = await countryApi.getById(countryId)
                let newCountryData = countryResponse.data.data.country;
                let newCountryName = newCountryData ? newCountryData.country : ''
                setCountryName(newCountryName);
            }
            setTotalPages(response.total_pages)
            setStates([...response.states])
        } catch (error) {
            console.error(error.message, error.stack);
        }
    }

    useEffect(()=>{
        if (search) {
            stateApi.search(countryId,search,pageItemActive).then((response) => {
                setTotalPages(response.total_pages)
                setStates([...response.states])
            })
            .catch((err) => {
                console.error(err.message, err.stack);
            });
        }else{
            fetch(countryId, pageItemActive)
        }
    },[ countryId, search, pageItemActive])

    const __handleSearch = (event) => {
        setSearch(event.target.value);
    };

    const handleSelectItem = (pageNumber)=>{
        setPageItemActive(pageNumber);
    }

    const getPaginationItems =()=>{
        let items = [];
        for (let number = 1; number <= totalPages; number++) {
            items.push(
                <Pagination.Item key={number} active={number === pageItemActive} onClick={()=>handleSelectItem(number)}>
                    {number}
                </Pagination.Item>,
            );
        }
        return items;
    }

    const previousPage = async()=>{
        let currentPageNumber = pageItemActive > 1 ? pageItemActive - 1 : pageItemActive
        setPageItemActive(currentPageNumber);
    }

    const nextPage = async()=>{
        let currentPageNumber = pageItemActive < totalPages ? pageItemActive + 1 : totalPages
        setPageItemActive(currentPageNumber);
    }

    const items = getPaginationItems()

    return (
        <div className='dashboard-content'>
            <DashboardHeader
                btnText="New Province/State" link={`/country/${countryId}/state/new`} />
            <div className='dashboard-content-container'>
                <div className='dashboard-content-header'>
                    {countryName ? <h2 className="municipality-title">{countryName}</h2> : <h2>Province/State</h2> }
                    <div>
                        <div className='dashboard-content-search'>
                            <input
                                type='text'
                                value={search}
                                placeholder='Search by name..'
                                className='dashboard-content-input'
                                onChange={e => __handleSearch(e)} />
                        </div>
                    </div>
                </div>
                <div className="organizations-custom-popup">
                    <PopupboxContainer />
                </div>
                    {
                        states.length > 0 ?
                        <div>
                            <StateTable states={states} {...props} refresh={fetch} />
                            {
                                totalPages > 1 && <div className="events-pagination-wrap">
                                    <Pagination size="sm">
                                        <Pagination.Prev onClick={() => previousPage()} />
                                        {items}
                                        <Pagination.Next onClick={() => nextPage()} />
                                    </Pagination>
                                </div>
                            }
                         </div>
                            :
                            <div className='dashboard-content-footer'>
                                <span className='empty-table'>No data</span>
                            </div>
                    }
               
            </div>
        </div>
    )
}

export default StatesPage