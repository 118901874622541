import {useState} from 'react';

import DropdownSelector from "../fragments/form/DropdownSelector"
import TextField from "../fragments/form/TextField"
import TextAreaField from "../fragments/form/TextAreaField"
import DateTimeSelector from "../fragments/form/DateTimeSelector"
import CheckboxSelector from "../fragments/form/CheckboxSelector"
import ImageManager from "../fragments/form/ImageManager"
import SubmitButton from "../fragments/form/SubmitButton"


const EditEventForm = (props) => {
    const [formData, setFormData] = useState(new FormData());

    const onChange = (id, value) => {
        formData.set(id, value);
        setFormData(formData);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        props.onSubmit(formData);
    }
    
    return (
        <form onSubmit={onSubmit}>
            <DropdownSelector id="location_id" required={true} collection={props.locations} onChange={onChange} value={props.event.location_id} label="Location" prompt="Please select a location" />
            <DropdownSelector id="category_id" required={true} collection={props.categories} onChange={onChange} value={props.event.category_id} label="Category" prompt="Please select a category" />
            <ImageManager id="card_image" required={false} url={props.event.card_image.url} label="Card Image" onChange={onChange} />
            <ImageManager id="profile_image" required={false} url={props.event.profile_image.url} label="Profile Image" onChange={onChange} />
            <TextField id="title" required={true} value={props.event.title} onChange={onChange} label="Title" />
            <TextAreaField id="description" required={true} value={props.event.description} onChange={onChange} label="Description" rows="10" cols="20"/>
            <TextField id="website" required={false} value={props.event.website} onChange={onChange} label="Website" />
            <TextField id="buy_ticket_url" required={false}  value={props.event.buy_ticket_url} onChange={onChange} label="Buy Ticket Url" />
            <DateTimeSelector id="start_time" required={true} value={props.event.start_time}  onChange={onChange} label="Start Time" />
            <DateTimeSelector id="end_time" required={true} value={props.event.end_time}  onChange={onChange} label="End Time" />
            <CheckboxSelector id="is_published" value={props.event.is_published} onChange={onChange} label="Publish" />
            <SubmitButton />
        </form>
    );
}

export default EditEventForm;
