import React, { useState, useEffect } from "react"
import MunicipalityApi from "../../services/municipalities";
import CountryApi from "../../services/countries";
import StateApi from "../../services/states";
import MunicipalityTable from "../../tables/MunicipleHierarchy/MunicipalityTable"
import DashboardHeader from '../../components/DashboardHeader';
import { PopupboxContainer } from 'react-popupbox';
import Pagination from 'react-bootstrap/Pagination';
import { useParams } from "react-router-dom";


function MunicipalitiesPage(props) {
    const [municipalities, setMunicipalities] = useState([])
    const [countryName, setCountryName] = useState('');
    const [stateName, setStateName] = useState();
    const [search, setSearch] = useState('');
    const [totalPages, setTotalPages] = useState(0);
    const [pageItemActive, setPageItemActive] = useState(1);
    const {countryId, stateId} = useParams()
    const municipalityApi = new MunicipalityApi(props.token);
    const countryApi = new CountryApi(props.token)
    const stateApi = new StateApi(props.token)

    const fetch = async (countryId, stateId, pageItemActive) => {
        try {
            let response = await municipalityApi.all(stateId, pageItemActive);
            setTotalPages(response.total_pages);
            setMunicipalities([...response.cities]);
            let countryData = response.countries.find((e) => e.id === parseInt(countryId));
            let stateData = response.states.find((e) => e.id === parseInt(stateId));
            if (countryData) {
                setCountryName(countryData.country);
            } else {
                let countryResponse = await countryApi.getById(countryId);
                let newCountryData = countryResponse.data.data.country;
                let newCountryName = newCountryData ? newCountryData.country : ''
                setCountryName(newCountryName);
            }

            if (stateData) {
                setStateName(stateData.state);
            } else {
                let stateResponse = await stateApi.getById(stateId);
                let newStateData = stateResponse.data.data.state;
                let newStateName = newStateData ? newStateData.state : ''
                setStateName(newStateName);
            }

        } catch (error) {
            console.error(error.message, error.stack);
        }
    }


    useEffect(()=>{
        if (search) {
            municipalityApi.search(stateId,search,pageItemActive).then((response) => {
                setTotalPages(response.total_pages)
                setMunicipalities([...response.cities])
            })
            .catch((err) => {
                console.error(err.message, err.stack);
            });
        }else{
            fetch(countryId, stateId,pageItemActive)
        }
    },[countryId, stateId, search, pageItemActive])

    const __handleSearch = (event) => {
        setSearch(event.target.value);
    };

    const handleSelectItem = (pageNumber)=>{
        setPageItemActive(pageNumber);
    }

    const getPaginationItems =()=>{
        let items = [];
        for (let number = 1; number <= totalPages; number++) {
            items.push(
                <Pagination.Item key={number} active={number === pageItemActive} onClick={()=>handleSelectItem(number)}>
                    {number}
                </Pagination.Item>,
            );
        }
        return items;
    }

    const previousPage = async()=>{
        let currentPageNumber = pageItemActive > 1 ? pageItemActive - 1 : pageItemActive
        setPageItemActive(currentPageNumber);
    }

    const nextPage = async()=>{
        let currentPageNumber = pageItemActive < totalPages ? pageItemActive + 1 : totalPages
        setPageItemActive(currentPageNumber);
    }

    const items = getPaginationItems()
   
    return (
        <div className='dashboard-content'>
            <DashboardHeader
                btnText="New Municipality" link={`/country/${countryId}/state/${stateId}/municipality/new`} />
            <div className='dashboard-content-container'>
                <div className='dashboard-content-header'>
                    {countryName && stateName ? <h2 className="municipality-title">{countryName} - {stateName}</h2> : <h2>Cities</h2>}
                    <div>
                        <div className='dashboard-content-search'>
                            <input
                                type='text'
                                value={search}
                                placeholder='Search by name..'
                                className='dashboard-content-input'
                                onChange={e => __handleSearch(e)} />
                        </div>
                    </div>
                </div>
                <div className="organizations-custom-popup">
                    <PopupboxContainer />
                </div>
                    {
                        municipalities.length > 0 ?
                        <div>
                            <MunicipalityTable municipalities={municipalities} {...props} refresh={fetch} />
                            {
                                totalPages > 1 && <div className="events-pagination-wrap">
                                    <Pagination size="sm">
                                        <Pagination.Prev onClick={() => previousPage()} />
                                        {items}
                                        <Pagination.Next onClick={() => nextPage()} />
                                    </Pagination>
                                </div>
                            }
                         </div>
                            :
                            <div className='dashboard-content-footer'>
                                <span className='empty-table'>No data</span>
                            </div>
                    }
               
            </div>
        </div>
    )
}

export default MunicipalitiesPage