import React, {useState, useEffect} from "react"
import OrganizationApi from "../../services/organizations";
import {useParams} from "react-router-dom";

import OrganizationPanel from "../../panels/OrganizationPanel"
import OrganizationHeaderNav from "../../fragments/nav/OrganizationHeaderNav"

import Page from "../Page"
import Loading from "../../fragments/Loading"

function OrganizationPage(props) {
    const [organization, setOrganization] = useState(null);
    const organizationApi = new OrganizationApi(props.token);
    const { organizationId } = useParams();

    useEffect(() => {
        organizationApi.getById(organizationId).then((response) => {
            setOrganization(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
    },[]);

    if(!organization) {
        return (<Loading />)
    }
    return(
        <Page 
            buttonText="Back"
            buttonUrl="/organizations"
            header={`Organization: ${organization.name}`}
        >
            <OrganizationHeaderNav organizationId={organization.id} />
            <OrganizationPanel organization={organization} />
        </Page>
    )
}

export default OrganizationPage;