import React, {useState} from 'react';
import ParticipantApi from "../services/participants";
import Error from "../fragments/form/Error"
import Message from "../fragments/form/Message"

import TextValue from "../fragments/table/TextValue"
import {Link} from "react-router-dom";
import ConfirmDeletePopup from "../fragments/popup/ConfirmDeletePopup";


function ParticipantsTable (props) {
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const participantApi = new ParticipantApi(props.token);
    let { organizationId, eventId , participants} = props;

    function deleteParticipant(id, name){
        participantApi.delete(id).then((response) => {
            setMessage(`Participant: "${name}" has been deleted`)
            setError("");
            props.refresh(props.eventId);
        })
        .catch((err) => {
            console.error(err.message);
            setError(err.message);
            setMessage("");
        });
    }

    return(
        <div>
            <Error message={error} />
            <Message message={message} />
            <table>
                <tbody>
                    <tr>
                        <th>ID</th>
                        <th>NAME</th>
                        <th>ACTIONS</th>
                    </tr>
                    {participants.map((participant, index) => {
                        return (
                            <tr key={index}>
                                <td><TextValue value={participant.id} /></td>
                                <td><TextValue value={participant.participant_name} /></td>
                                <td>
                                    <span>
                                        <Link to={`/organization/${organizationId}/event/${eventId}/participant/${participant.id}`}>
                                            <i className="bi">View</i>
                                        </Link>
                                        <br />
                                        <ConfirmDeletePopup
                                            onConfirm={() => deleteParticipant(participant.id, participant.participant_name)}
                                            prompt={`Are you sure to delete ${participant.participant_name} ?`}
                                            acceptLabel="Delete"
                                        />
                                    </span>
                                </td>
                            </tr>
                        )
                    }
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default ParticipantsTable;