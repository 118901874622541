import React, {useState, useEffect} from "react"
import OrganizationApi from "../services/organizations";
import LocationApi from "../services/locations";
import {useParams, useNavigate} from "react-router-dom";

import Error from "../fragments/form/Error"
import Message from "../fragments/form/Message"
import TextField from "../fragments/form/TextField"
import TextAreaField from "../fragments/form/TextAreaField"
import CheckboxSelector from "../fragments/form/CheckboxSelector"
import ImageManager from "../fragments/form/ImageManager"
import SubmitButton from "../fragments/form/SubmitButton"
import DropdownSelector from "../fragments/form/DropdownSelector";

function EditOrganizationForm(props) {
    const [formData, setFormData] = useState(new FormData());
    const [organization, setOrganization] = useState(null);
    const [locations, setLocations] = useState(null);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);

    const organizationApi = new OrganizationApi(props.token);
    const locationsApi = new LocationApi(props.token);

    const navigate = useNavigate();
    const { organizationId } = useParams();

    const onChange = (id, value) => {
        formData.set(id, value);
        setFormData(formData);
    }

    const handleOnSubmit = e => {
        e.preventDefault();
        organizationApi.update(organizationId, formData).then(() => {
            navigate(`/organization/${organizationId}`);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
            setError(err.message)
            setMessage("");
        });
    }

    useEffect(() => {
        organizationApi.getById(organizationId).then((response) => {
            setOrganization(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });

        locationsApi.getByOrganizationId(organizationId).then((response) => {
            setLocations(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
    },[]);

    if(!organization) {
        return (<div>Loading...</div>)
    }
    return(
        <div>
            <Error message={error} />
            <Message message={message} />

            {
                props.organization.is_corkboard ?   <form onSubmit={handleOnSubmit}>
                <TextField id="name" required={true} value={props.organization.name} onChange={onChange} label="Title" />
                <CheckboxSelector id="is_published" value={props.organization.is_published} onChange={onChange} label="Publish" />
                <SubmitButton />
            </form>
            :
            <form onSubmit={handleOnSubmit}>
                <DropdownSelector id="category_id" required={true} collection={props.categories} onChange={onChange} label="Category" prompt="Please select a category"  onLoad={() => onChange("category_id", 0)} selectedId={organization.category_id} />
                <TextField id="name" required={true} value={props.organization.name} onChange={onChange} label="Title" />
                <DropdownSelector id="location_id" required={true} collection={locations} onChange={onChange} selectedId={props.organization.location_id} label="Location" prompt="Please select a location" />   
                <TextAreaField id="description" required={true} value={props.organization.description} onChange={onChange} label="Description" rows="10" cols="20"/>
                <TextField id="website_url" required={false} value={props.organization.website_url} onChange={onChange} label="Website" />
                <TextField id="twitter_name" required={false} value={props.organization.twitter_name}  onChange={onChange} label="Twitter Name" />
                <TextField id="facebook_name" required={false} value={props.organization.facebook_name}  onChange={onChange} label="Facebook Name" />
                <TextField id="instagram_name" required={false} value={props.organization.instagram_name}  onChange={onChange} label="Instagram Name" />
                <TextField id="linkedin_name" required={false} value={props.organization.linkedin_name}  onChange={onChange} label="LinkedIn Name" />
                <TextField id="email_address" required={false} value={props.organization.email_address}  onChange={onChange} label="Email Address" />
                <TextField id="phone_number" required={false} value={props.organization.phone_number}  onChange={onChange} label="Phone Number" />
                <ImageManager id="card_image" required={false} url={props.organization.card_image.url} label="Card Image" onChange={onChange} />
                <ImageManager id="profile_image" required={false} url={props.organization.profile_image.url} label="Profile Image" onChange={onChange} />
                <CheckboxSelector id="is_published" value={props.organization.is_published} onChange={onChange} label="Publish" />
                <SubmitButton />
            </form>
            }
        </div>
    )
}

export default EditOrganizationForm;