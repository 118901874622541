import TextPanelItem from "../fragments/panel/TextPanelItem"
import BoolPanelItem from "../fragments/panel/BoolPanelItem"
import ImagePanelItem from "../fragments/panel/ImagePanelItem"
import Panel from "../fragments/panel/Panel"

const ParticipantRolePanel = (props) => (
    <Panel>
        <TextPanelItem value={props.participant.id} label="ID" />
        <TextPanelItem value={props.participant.name} label="Name" />
        <TextPanelItem value={props.participant.description} label="Description" />
        <TextPanelItem value={props.participant.website_url}  label="Website" />
        <TextPanelItem value={props.participant.twitter_name}  label="Twitter Name" />
        <TextPanelItem value={props.participant.facebook_name}  label="Facebook Name" />
        <TextPanelItem value={props.participant.instagram_name}  label="Instagram Name" />
        <TextPanelItem value={props.participant.linkedin_name}  label="Linkedin Name" />
        <TextPanelItem value={props.participant.email_address}  label="Email" />
        <TextPanelItem value={props.participant.phone_number}  label="Phone Number" />
        <ImagePanelItem url={props.participant.card_image.url} label="Card Image"  />
        <ImagePanelItem url={props.participant.profile_image.url} label="Profile Image" />
        <TextPanelItem value={props.participant.participant_role_name} label="Role" />
        <BoolPanelItem value={props.participant.is_published}  label="Published?" />
    </Panel>
)

export default ParticipantRolePanel;