import React, {useState, useEffect} from "react"
import CategoryApi from "../../services/categories";
import OrganizationApi from "../../services/organizations";
import {useParams} from "react-router-dom";

import Page from "../Page";
import Loading from "../../fragments/Loading";

import EditOrganizationForm from "../../forms/EditOrganizationForm"

function EditOrganizationPage(props) {
    const [organization, setOrganization] = useState(null);
    const organizationApi = new OrganizationApi(props.token);
    const categoryApi = new CategoryApi(props.token);
    const [categories, setCategories] = useState();
    const { organizationId } = useParams();

    useEffect(() => {
        categoryApi.all().then((categories) => {
            setCategories(Object.values(categories.filter(category => category.is_organization)));
        })
        .catch((err) => {
            console.error(err.message);
        });
        organizationApi.getById(organizationId).then((response) => {
            setOrganization(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
    },[]);

    if(!organization) {
        return (<Loading />)
    }
    return(
        <Page
            buttonText="Back"
            buttonUrl={`/organization/${organizationId}`}
            header={`Edit Organization: ${organizationId}`}
        >
            <EditOrganizationForm
                organization={organization}
                categories={categories}
                {...props}
            />
        </Page>
    )
}

export default EditOrganizationPage;