import React, {useState, useEffect} from "react"
import {useParams} from "react-router-dom";

import OrganizationApi from "../../services/organizations";
import ArchiveApi from "../../services/archivedEvents";

import EventsTable from "../../tables/EventsTable"
import OrganizationHeaderNav from "../../fragments/nav/OrganizationHeaderNav"

import Page from "../Page"
import Loading from "../../fragments/Loading";
import Pagination from 'react-bootstrap/Pagination';


function ArchivedEventsPage(props) {
    const [archiveList, setArchiveList] = useState();
    const [organization, setOrganization] = useState();
    const [totalPages, setTotalPages] = useState(0);
    const [pageItemActive, setPageItemActive] = useState(1);

    const archiveApi = new ArchiveApi(props.token);
    const organizationApi = new OrganizationApi(props.token);
    const { organizationId } = useParams();

    const fetch = () => {
        archiveApi.getArchiveList(organizationId, pageItemActive).then((response) => {
            setArchiveList(response.events);
            setTotalPages(response.total_pages)
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
    }

    useEffect(() => {
        organizationApi.getById(organizationId).then((response) => {
            setOrganization(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
        fetch();
    },[]);

    if(!archiveList || !organization) {
        return (<Loading />)
    }

    const handleSelectItem = async (organizationId,pageNumber )=>{
        setPageItemActive(pageNumber);
        try {
           let response = await archiveApi.getArchiveList(organizationId,pageNumber);
            setArchiveList(response.events);
            setTotalPages(response.total_pages)
        } catch (error) {
            console.error(error);
        }
    }

    const getPaginationItems =()=>{
        let items = [];
        for (let number = 1; number <= totalPages; number++) {
            items.push(
                <Pagination.Item key={number} active={number === pageItemActive} onClick={()=>handleSelectItem(organizationId,number)}>
                    {number}
                </Pagination.Item>,
            );
        }
        return items;
    }

    const previousPage = async(organizationId)=>{
        let currentPageNumber = pageItemActive > 1 ? pageItemActive - 1 : pageItemActive
        setPageItemActive(currentPageNumber);
        try {
           let response = await archiveApi.getArchiveList(organizationId,currentPageNumber);
            setArchiveList(response.events);
            setTotalPages(response.total_pages)
        } catch (error) {
            console.error(error);
        }
    }

    const nextPage = async(organizationId)=>{
        let currentPageNumber = pageItemActive < totalPages ? pageItemActive + 1 : totalPages
        setPageItemActive(currentPageNumber);
        try {
           let response = await archiveApi.getArchiveList(organizationId,currentPageNumber);
           setArchiveList(response.events);
            setTotalPages(response.total_pages)
        } catch (error) {
            console.error(error);
        }
    }

    const items = getPaginationItems()
    return(
        <Page 
            buttonText="Create Event"
            buttonUrl={`/organization/${organizationId}/event/new`}
            header={`Archived events for the organization: ${organization.name}`}
        >
            <OrganizationHeaderNav organizationId={organizationId} isShowArchive={true} />
            {
               archiveList && archiveList.length > 0 ? <EventsTable
                    events={archiveList}
                    organization={organization}
                    refresh={fetch}
                    {...props} />
                    :
                    <div className='dashboard-content-footer'>
                        <span className='empty-table'>No data</span>
                    </div>
            }
            {
                totalPages > 1 && <div className="events-pagination-wrap">
                    <Pagination size="sm">
                        <Pagination.Prev onClick={()=>previousPage(organizationId)}/>
                        {items}
                        <Pagination.Next onClick={()=>nextPage(organizationId)}/>
                    </Pagination>
                </div>
            }
        </Page>
    )
}

export default ArchivedEventsPage;