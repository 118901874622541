import React, {useState, useEffect} from "react"
import {useParams} from "react-router-dom";

import OrganizationApi from "../../services/organizations";
import LocationApi from "../../services/locations";
import OrganizationHeaderNav from "../../fragments/nav/OrganizationHeaderNav"
import LocationPanel from "../../panels/LocationPanel"

import Page from "../Page"
import Loading from "../../fragments/Loading"

function OrganizationLocationPage(props) {
    const [location, setLocation] = useState();
    const [organization, setOrganization] = useState();

    const locationApi = new LocationApi(props.token);
    const organizationApi = new OrganizationApi(props.token);

    const { organizationId, locationId } = useParams();

    useEffect(() => {
        organizationApi.getById(organizationId).then((response) => {
            setOrganization(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
        locationApi.getById(locationId).then((response) => {
            setLocation(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
    },[]);

    if(!organization || !location) {
        return (<Loading />)
    }
    return(
        <Page 
            buttonText="Back"
            buttonUrl={`/organization/${organizationId}/locations`}
            header={`Viewing location "${location.name}" for organization: ${organization.name}`}
        >
            <OrganizationHeaderNav organizationId={organizationId} />
            <LocationPanel
                location={location}
                organization={organization}
            />
        </Page>
    )
}

export default OrganizationLocationPage;