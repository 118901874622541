
import {useState} from 'react';

import TextField from "../fragments/form/TextField"
import TextAreaField from "../fragments/form/TextAreaField"
import CheckboxSelector from "../fragments/form/CheckboxSelector"
import ImageSelector from "../fragments/form/ImageSelector"
import DropdownSelector from "../fragments/form/DropdownSelector"
import SubmitButton from "../fragments/form/SubmitButton"


function CreateOrganizationForm(props) {
    const [formData, setFormData] = useState(new FormData());
    const [locationFormData, setLocationFormData]= useState(new FormData());

    const onChange = (id, value) => {
        formData.set(id, value);
        setFormData(formData);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        props.onSubmit(formData, locationFormData)
    }

    const onChangeLocation =(id, value)=>{
        locationFormData.set(id, value);
        if(id === 'country'){
            props.handleSetCountryId(value)
            let countryData = props.countries.find((e) => e.id === parseInt(value))
            let countryValue = countryData ? countryData.country : '';
            locationFormData.set(id, countryValue);
        }
        if(id === 'state'){
            props.handleSetStateId(value) 
            let stateData = props.states.find((e) => e.id === parseInt(value))
            let stateValue = stateData ? stateData.state : '';
            locationFormData.set(id, stateValue);
        }
        if(id === 'city'){
            let cityData = props.cities.find((e) => e.id === parseInt(value))
            let cityValue = cityData ? cityData.city : '';
            locationFormData.set(id, cityValue);
        }
        setLocationFormData(formData);
    }

    return (
        <form onSubmit={onSubmit}>
            <DropdownSelector id="category_id" required={true} collection={props.categories} onChange={onChange} label="Category" prompt="Please select a category"  onLoad={() => onChange("category_id", 0)} />
            <TextField id="name" required={true} onChange={onChange} label="Title" onLoad={() => onChange("name", "")}  />
            <TextAreaField id="description" required={true} onChange={onChange} label="Description" rows="10" cols="20" onLoad={() => onChange("description", "")} />
            <TextField id="website_url" required={false} onChange={onChange} label="Website" onLoad={() => onChange("website_url", "")}  />
            <TextField id="twitter_name" required={false} onChange={onChange} label="Twitter Name" onLoad={() => onChange("twitter_name", "")}  />
            <TextField id="facebook_name" required={false} onChange={onChange} label="Facebook Name" onLoad={() => onChange("facebook_name", "")}  />
            <TextField id="instagram_name" required={false} onChange={onChange} label="Instagram Name" onLoad={() => onChange("instagram_name", "")}  />
            <TextField id="linkedin_name" required={false} onChange={onChange} label="LinkedIn Name" onLoad={() => onChange("linkedin_name", "")}  />
            <TextField id="email_address" required={false} onChange={onChange} label="Email Address" onLoad={() => onChange("email_address", "")}  />
            <TextField id="phone_number" required={false} onChange={onChange} label="Phone Number" onLoad={() => onChange("phone_number", "")}  />
            <ImageSelector id="card_image" required={true} label="Card Image" onChange={onChange} onLoad={() => onChange("card_image", null)}  />
            <ImageSelector id="profile_image" required={true} label="Profile Image" onChange={onChange} onLoad={() => onChange("profile_image", null)}  />
            <TextField id="address_1" required={true} onChange={onChangeLocation} label="Address 1" onLoad={() => onChangeLocation("address_1", "")}  />
            <TextField id="address_2" required={true} onChange={onChangeLocation} label="Address 2" onLoad={() => onChangeLocation("address_2", "")}  />
            <DropdownSelector id="country" required={true} collection={props.countries} onChange={onChangeLocation} label="Country" prompt="Please select a country"  onLoad={() => onChangeLocation("country", '')} />
            <DropdownSelector id="state"  required={true} collection={props.states} onChange={onChangeLocation} label="State" prompt="Please select a country before select a state"  onLoad={() => onChangeLocation("state", '')} />
            <DropdownSelector id="city"  required={true} collection={props.cities} onChange={onChangeLocation} label="City" prompt="Please select a state before select a city"  onLoad={() => onChangeLocation("city", '')} />
            {/* <TextField id="city" required={true} onChange={onChangeLocation} label="City" onLoad={() => onChangeLocation("city", "")}  /> */}
            {/* <TextField id="state" required={true} onChange={onChangeLocation} label="State" onLoad={() => onChangeLocation("state", "")}  /> */}
            <TextField id="zip" required={true} onChange={onChangeLocation} label="Zip" onLoad={() => onChangeLocation("zip", "")}  />
            {/* <TextField id="country" required={true} onChange={onChangeLocation} label="Country" onLoad={() => onChangeLocation("country", "")}  /> */}
            <TextField id="longitude" type="number" required={true} onChange={onChangeLocation} label="Longtitude" onLoad={() => onChangeLocation("longitude", "")}  />
            <TextField id="latitude" type="number" required={true} onChange={onChangeLocation} label="Latitude" onLoad={() => onChangeLocation("latitude", "")}  />
            <CheckboxSelector id="is_published" required={true} onChange={onChange} label="Publish" onLoad={() => onChange("is_published", "0")} />
            <SubmitButton />
        </form>
    );
}

export default CreateOrganizationForm;