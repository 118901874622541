import API from './api'

const {REACT_APP_API_URI} = process.env;



const updateProperty = (subject, keyProperty, valueProperty, collection, defaultValue) => {
    if(typeof defaultValue == "undefined") {
        defaultValue = null;
    }
    const key = subject[keyProperty];
    if(key && collection[key]) {
        subject[valueProperty] = collection[key]
    } else {
        subject[valueProperty] = defaultValue;
    }
    return subject
}

const formatOrganizations = (response) => {
    let organizations = Object.values(response.data.data.organizations);
    const users = response.data.data.users;
    const images = response.data.data.images;
    const categories = response.data.data.categories;
    const locations = Object.values(response.data.data.locations)
  
    return organizations.map((organization) => {
        organization = updateProperty(organization, "created_by", "created_by", users);
        organization = updateProperty(organization, "updated_by", "updated_by", users);
        organization = updateProperty(organization, "deleted_by", "deleted_by", users);
        
        organization = updateProperty(organization, "profile_image_id", "profile_image", images);
        organization = updateProperty(organization, "card_image_id", "card_image", images);
        let location = locations.find((item) => item.id === organization.location_id)
        organization.city = location && location.city ? location.city : '';
        organization.state = location && location.state ? location.state : '';
        organization.country = location &&  location.country ? location.country : '';
        return organization;
    });
}


const formatOrganization = (response) => {
    let organization = response.data.data.organization;
    const users = response.data.data.users;
    const images = response.data.data.images;

    organization = updateProperty(organization, "created_by", "created_by", users);
    organization = updateProperty(organization, "updated_by", "updated_by", users);
    organization = updateProperty(organization, "deleted_by", "deleted_by", users);
    organization = updateProperty(organization, "card_image_id", "card_image", images);
    organization = updateProperty(organization, "profile_image_id", "profile_image", images);
    return organization
}

class OrganizationApi {
    constructor(token) {
        this.api = new API(token);
        this.baseUrl = `${REACT_APP_API_URI}/admin/organization`
    }

    all(status) {
        try {
            return this.api.get(`${this.baseUrl}?status=${status}`)
            .then((response) => {
                return formatOrganizations(response);
            });
        }catch(error) {
            console.error(error);
            return []
        }
    }


    search(status) {
        try {
            return this.api.get(`${this.baseUrl}?status=${status}`)
            .then((response) => {
                return formatOrganizations(response);
            });
        }catch(error) {
            console.error(error);
            return []
        }
    }

    getById(id) {
        try {
            return this.api.get(`${this.baseUrl}/${id}`)
            .then((response) => {
                return formatOrganization(response);
            });
        } catch(error) {
            console.error(`Error fetching organization. id=${id}, error=${error}`);
            return {}
        }
    }

    update(id, body) {
        try{
            return this.api.patch(`${this.baseUrl}/${id}`, body, "multipart/form-data")
            .then((response) => {
                return formatOrganization(response);
            });
        }catch(error) {
            console.error(`Error updating organization. id=${id}, error=${error}`);
            return {}
        }
    }

    create(body) {
        try{
            return this.api.post(this.baseUrl, body, "multipart/form-data")
            .then((response) => {
                return formatOrganization(response);
            });
        }catch(error) {
            console.error(`Error creating organization. error=${error}`);
            return{}
        }
    }

    delete(id) {
        try{
            return this.api.delete(`${this.baseUrl}/${id}`)
            .then((response) => {
                return response.data.data;
            });
        }catch(error) {
            console.error(`Error deleting organization. error=${error}`);
            return {}
        }
    }

    searchByCity(param, status) {
        try {
            return this.api.get(`${this.baseUrl}?city=${param}&status=${status}`)
            .then((response) => {
                return formatOrganizations(response);
            });
        }catch(error) {
            console.error(error);
            return []
        }
    }    

    searchUnpublishedOrganization(status) {
        try {
            return this.api.get(`${this.baseUrl}?status=${status}&corkboard=${'All'}`)
            .then((response) => {
                return formatOrganizations(response);
            });
        }catch(error) {
            console.error(error);
            return []
        }
    }

    searchUnpublishedOrganizationByCity(param, status) {
        try {
            return this.api.get(`${this.baseUrl}?city=${param}&status=${status}&corkboard=${'All'}`)
            .then((response) => {
                return formatOrganizations(response);
            });
        }catch(error) {
            console.error(error);
            return []
        }
    }  

}

export default OrganizationApi;