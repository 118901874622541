import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from "react-router-dom";
import EventsApi from "../../services/events";
import OrganizationApi from "../../services/organizations";
import CategoryApi from "../../services/categories";
import LocationApi from "../../services/locations";
import Page from "../Page"
import Loading from "../../fragments/Loading"
import Error from "../../fragments/form/Error"
import Message from "../../fragments/form/Message"

import OrganizationHeaderNav from "../../fragments/nav/OrganizationHeaderNav"
import CreateEventForm from "../../forms/CreateEventForm"

const CreateOrganizationEventPage = (props) => {
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [organization, setOrganization] = useState();
    const [locations, setLocations] = useState();
    const [categories, setCategories] = useState();

    const eventApi = new EventsApi(props.token);
    const categoryApi = new CategoryApi(props.token);
    const locationApi = new LocationApi(props.token);
    const organizationApi = new OrganizationApi(props.token);

    const { organizationId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        organizationApi.getById(organizationId).then((response) => {
            setOrganization(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });

        categoryApi.all().then((categories) => {
            setCategories(Object.values(categories.filter(category => category.is_event)));
        })
        .catch((err) => {
            console.error(err.message);
        });
        
        locationApi.getByOrganizationId(organizationId).then((response) => {
            setLocations(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });
    }, []);

    const onSubmit = (formData) => {
        formData.set("organization_id", organizationId);
        eventApi.create(formData).then((response) => {
            navigate(`/organization/${organizationId}/events`);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
            setError(err.message)
            setMessage("");
        });
    }
    
    if(!organization || !categories || !locations) {
        return (<Loading />)
    }

    return (
        <Page
            buttonText="Back"
            buttonUrl={`/organization/${organizationId}/events`}
            header={`Create Event`}
        >
            <OrganizationHeaderNav organizationId={organizationId} />
            <Error message={error} />
            <Message message={message} />
            <CreateEventForm
                onSubmit={onSubmit}
                organization={organization}
                locations={locations}
                categories={categories}
                {...props}  />
        </Page>
    );
}

export default CreateOrganizationEventPage;