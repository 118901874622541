import React, {useState} from "react";
import {BrowserRouter as Router, Navigate, Routes, Route} from "react-router-dom";


//pages
import HomePage from "./pages/HomePage"

import Layout from "./components/layout/Layout";

import Orders from "./pages/Orders";
import {AuthProvider} from "./services/AuthContext";
import GoogleAuth from "./services/GoogleAuth";

import Blogs from "./pages/Blog";
import SingleBlogPost from "./pages/Blog/single";

import Categories from "./pages/Category";
import SingleCategory from "./pages/Category/single";
import Newcategory from "./pages/Category/newcategory";

import Slides from "./pages/Slides";
import SingleSlide from "./pages/Slides/single";
import NewSlide from "./pages/Slides/newslide";

import Sponsors from "./pages/Sponsors";
import SingleSponsor from "./pages/Sponsors/single";
import NewSponsor from "./pages/Sponsors/newsponsor";

import OrganizationEventPage from "./pages/Events/OrganizationEventPage";
import CreateOrganizationEventPage from "./pages/Events/CreateOrganizationEventPage";
import EditOrganizationEventPage from "./pages/Events/EditOrganizationEventPage";
import OrganizationEventsPage from "./pages/Events/OrganizationEventsPage";

import OrganizationsPage from "./pages/Organizations/OrganizationsPage";
import CreateOrganizationPage from "./pages/Organizations/CreateOrganizationPage";
import EditOrganizationPage from "./pages/Organizations/EditOrganizationPage";
import OrganizationPage from "./pages/Organizations/OrganizationPage";

import OrganizationLocationPage from "./pages/Locations/OrganizationLocationPage"
import OrganizationLocationsPage from "./pages/Locations/OrganizationLocationsPage"
import CreateOrganizationLocationPage from "./pages/Locations/CreateOrganizationLocationPage";
import EditOrganizationLocationPage from "./pages/Locations/EditOrganizationLocationPage";

import SponsorsPage from "./pages/Sponsorships/SponsorsPage";
import CreateSponsorPage from "./pages/Sponsorships/CreateSponsorPage";
import SponsorPage from "./pages/Sponsorships/SponsorPage"

import ParticipantsPage from "./pages/Participants/ParticipantsPage";
import ParticipantPage from "./pages/Participants/ParticipantPage";
import CreateParticipantPage from "./pages/Participants/CreateParticipantPage";

import ParticipantRolesPage from "./pages/Participants/ParticipantRolesPage";
import CreateParticipantRolePage from "./pages/Participants/CreateParticipantRolePage";
import ParticipantRolePage from "./pages/Participants/ParticipantRolePage";

import ArchivedEventsPage from "./pages/ArchivedEvents/ArchivedEventsPage";

import OrganizationsUnpublishedPage from "./pages/Unpublished/Organizations/OrganizationsPage";
import EventsUnpublishedPage from "./pages/Unpublished/Events/EventsPage"

import CountriesPage from "./pages/MunicipleHierarchy/CountriesPage"
import EditCountryPage from "./pages/MunicipleHierarchy/EditCountryPage"
import CreateCountryPage from "./pages/MunicipleHierarchy/CreateCountryPage"

import StatesPage from "./pages/MunicipleHierarchy/StatesPage"
import CreateStatePage from "./pages/MunicipleHierarchy/CreateStatePage"
import EditStatePage from "./pages/MunicipleHierarchy/EditStatePage"
import MunicipalitiesPage from "./pages/MunicipleHierarchy/MunicipalitiesPage"
import CreateMunicipalityPage from "./pages/MunicipleHierarchy/CreateMunicipalityPage";
import MunicipalityPage from "./pages/MunicipleHierarchy/MunicipalityPage";
import EditMunicipalityPage from "./pages/MunicipleHierarchy/EditMunicipalityPage";


const AuthElement = (props) =>  (
    <GoogleAuth  {...props} />
)

const AuthenticatedRoute = (props) => {
    if(!props.token) {
        return (<AuthElement {...props} />)
    }
    return (
        <Layout>
            {props.children}
        </Layout>
    )
}

function Routing() {
    const [pageProps, setPageProps] = useState({});
    
    const onAuthSuccess = (authToken) => {
        setPageProps({"token": authToken});
    }
    const onAuthFail = (err) => {
        console.error(err, err.stack);
    }
    return (
        <Router>
            <AuthProvider value={pageProps.token}>
            <Routes>
                <Route exact path='/' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <HomePage {...pageProps} />
                    </AuthenticatedRoute>
                }/>
       
                { /** Organizations */}
                <Route exact path='/organizations' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <OrganizationsPage {...pageProps} />
                    </AuthenticatedRoute>
                }/>
                <Route exact path='/organization/new' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <CreateOrganizationPage {...pageProps} />
                    </AuthenticatedRoute>
                }/>

                <Route exact path='/organization/:organizationId' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <OrganizationPage {...pageProps} />
                    </AuthenticatedRoute>
                }/>

                <Route exact path='/organization/:organizationId/edit' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <EditOrganizationPage {...pageProps} />
                    </AuthenticatedRoute>
                }/>

                { /** Events */}
                <Route exact path='/organization/:organizationId/event/new' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <CreateOrganizationEventPage {...pageProps} />
                    </AuthenticatedRoute>
                }/>
                <Route exact path='/organization/:organizationId/event/:eventId/edit' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <EditOrganizationEventPage {...pageProps} />
                    </AuthenticatedRoute>
                }/>
                <Route exact path='/organization/:organizationId/event/:eventId' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <OrganizationEventPage {...pageProps} />
                    </AuthenticatedRoute>
                }/>
                <Route exact path='/organization/:organizationId/events' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <OrganizationEventsPage {...pageProps} />
                    </AuthenticatedRoute>
                }/>

                {/* event participants */}
                <Route exact path='/organization/:organizationId/event/:eventId/participants' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <ParticipantsPage {...pageProps}/>
                    </AuthenticatedRoute>
                }/>
                  <Route exact path='/organization/:organizationId/event/:eventId/participant/new' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <CreateParticipantPage {...pageProps} />
                    </AuthenticatedRoute>
                } />
                <Route exact path='/organization/:organizationId/event/:eventId/participant/:participantId' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <ParticipantPage {...pageProps} />
                    </AuthenticatedRoute>
                } />

                  {/* event participant roles */}
                  <Route exact path='/organization/:organizationId/event/:eventId/participant/roles' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <ParticipantRolesPage {...pageProps}/>
                    </AuthenticatedRoute>
                }/>
                  <Route exact path='/organization/:organizationId/event/:eventId/participant/role/new' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <CreateParticipantRolePage {...pageProps} />
                    </AuthenticatedRoute>
                } />
                <Route exact path='/organization/:organizationId/event/:eventId/participant/role/:roleId' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <ParticipantRolePage {...pageProps} />
                    </AuthenticatedRoute>
                } />

                {/* event sponsors */}
                <Route exact path='/organization/:organizationId/event/:eventId/sponsors' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <SponsorsPage {...pageProps}/>
                    </AuthenticatedRoute>
                }/>
                <Route exact path='/organization/:organizationId/event/:eventId/sponsor/new' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <CreateSponsorPage {...pageProps} />
                    </AuthenticatedRoute>
                } />
                  <Route exact path='/organization/:organizationId/event/:eventId/sponsor/:sponsorId' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <SponsorPage {...pageProps} />
                    </AuthenticatedRoute>
                } />
                
                { /** Locations */}

                <Route exact path='/organization/:organizationId/locations' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <OrganizationLocationsPage {...pageProps} />
                    </AuthenticatedRoute>
                }/>
                <Route exact path='/organization/:organizationId/location/new' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <CreateOrganizationLocationPage {...pageProps} />
                    </AuthenticatedRoute>
                }/>
                <Route exact path='/organization/:organizationId/location/:locationId' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <OrganizationLocationPage {...pageProps} />
                    </AuthenticatedRoute>
                }/>
                <Route exact path='/organization/:organizationId/location/:locationId/edit' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <EditOrganizationLocationPage {...pageProps} />
                    </AuthenticatedRoute>
                }/>

                {/* Archive */}
                <Route exact path='/organization/:organizationId/archive' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <ArchivedEventsPage {...pageProps} />
                    </AuthenticatedRoute>
                }/>

                {/* Unpublished */}
                <Route exact path='/unpublished/organizations' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <OrganizationsUnpublishedPage {...pageProps} />
                    </AuthenticatedRoute>
                }/>
                 <Route exact path='/unpublished/events' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <EventsUnpublishedPage {...pageProps} />
                    </AuthenticatedRoute>
                }/>

                {/* Municiple Hierarchy */}
                <Route exact path='/countries' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <CountriesPage {...pageProps} />
                    </AuthenticatedRoute>
                }/>
                  <Route exact path='/country/new' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <CreateCountryPage {...pageProps} />
                    </AuthenticatedRoute>
                } />
                <Route exact path='/country/:countryId/edit' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <EditCountryPage {...pageProps} />
                    </AuthenticatedRoute>
                } />
                  <Route exact path='/country/:countryId/states' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                       <StatesPage {...pageProps} />
                    </AuthenticatedRoute>
                } />
                   <Route exact path='/country/:countryId/state/new' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                       <CreateStatePage {...pageProps} />
                    </AuthenticatedRoute>
                } />
                <Route exact path='/country/:countryId/state/:stateId/edit' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <EditStatePage {...pageProps} />
                    </AuthenticatedRoute>
                } />
                 <Route exact path='/country/:countryId/state/:stateId/municipalities' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <MunicipalitiesPage {...pageProps} />
                    </AuthenticatedRoute>
                } />
                <Route exact path='/country/:countryId/state/:stateId/municipality/new' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <CreateMunicipalityPage {...pageProps} />
                    </AuthenticatedRoute>
                } />
               
                  <Route exact path='/country/:countryId/state/:stateId/municipality/:municipalityId' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <MunicipalityPage {...pageProps} />
                    </AuthenticatedRoute>
                } />
                <Route exact path='/country/:countryId/state/:stateId/municipality/:municipalityId/edit' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <EditMunicipalityPage {...pageProps} />
                    </AuthenticatedRoute>
                } />
                { /** Everything else */}
                <Route exact path='/blog' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                            <Blogs {...pageProps} />
                        </AuthenticatedRoute>
                }/>
                <Route exact path='/orders' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <Orders {...pageProps} />
                    </AuthenticatedRoute>
                }/>
                <Route exact path='/categories' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <Categories {...pageProps} />
                    </AuthenticatedRoute>
                }/>
                <Route exact path='/slides' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <Slides {...pageProps} />
                    </AuthenticatedRoute>
                }/>
                <Route exact path='/sponsors' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <Sponsors {...pageProps} />
                    </AuthenticatedRoute>
                }/>

                <Route exact path='/singleblog' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <SingleBlogPost {...pageProps} />
                    </AuthenticatedRoute>
                }/>
                <Route exact path='/singlecategory' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <SingleCategory {...pageProps} />
                    </AuthenticatedRoute>
                }/>
                <Route exact path='/newcategory' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <Newcategory {...pageProps} />
                    </AuthenticatedRoute>
                }/>
                <Route exact path='/singleslide' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <SingleSlide {...pageProps} />
                    </AuthenticatedRoute>
                }/>
                <Route exact path='/newslide' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <NewSlide {...pageProps} />
                    </AuthenticatedRoute>
                }/>
                <Route exact path='/singlesponsor' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <SingleSponsor {...pageProps} />
                    </AuthenticatedRoute>
                }/>
                <Route exact path='/newsponsor' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <NewSponsor {...pageProps} />
                    </AuthenticatedRoute>
                }/>
                <Route exact path='/login' element={
                    <AuthenticatedRoute {...pageProps} onAuthFail={onAuthFail} onAuthSuccess={onAuthSuccess}>
                        <Navigate to="/" replace />
                    </AuthenticatedRoute>
                }/>
            </Routes>
            </AuthProvider>
        </Router>
    );
}

export default Routing
