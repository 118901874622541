import React, {useState, useEffect, useReducer} from "react"
import MunicipalityApi from "../../services/municipalities";
import StateApi from "../../services/states";
import CountryApi from "../../services/countries"
import {useParams} from "react-router-dom";
import Page from "../Page"
import Loading from "../../fragments/Loading"
import OrganizationsTable from "../../tables/MunicipleHierarchy/OrganizationTableByCity";
import Pagination from 'react-bootstrap/Pagination';

function MunicipalityPage(props) {
    const [municipality, setMunicipality] = useState(null);
    const [cityName, setCityName] = useState('');
    const [countryName, setCountryName] = useState('');
    const [stateName, setStateName] = useState('');
    const [organizations, setOrganizations] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [pageItemActive, setPageItemActive] = useState(1);
    const [sortByName, setSortByName] = useState(''); 
    const [sortById, setSortById] = useState('ASC');
    const municipalityApi = new MunicipalityApi(props.token);
    const countryApi  = new CountryApi(props.token)
    const stateApi = new StateApi(props.token)
    const { countryId, stateId ,municipalityId } = useParams();

    useEffect(() => {
        if(cityName){
            municipalityApi.getOrganizationsByCity(cityName, pageItemActive).then((response) => {
                setOrganizations([...response.organizations]);
                setTotalPages(response.total_pages)
            })
            .catch((err) => {
                console.error(err.message, err.stack);
            });
        }else{
            setOrganizations([]);
            setTotalPages(0)
        }
    },[cityName, pageItemActive]);

    const getCountryStateAndMunicipalityById = async (countryId, stateId, municipalityId) => {
        try {
            if (countryId) {
                let countryResponse = await countryApi.getById(countryId);
                let newCountryData = countryResponse.data.data.country;
                let newCountryName = newCountryData ? newCountryData.country : '';
                setCountryName(newCountryName);
            } else {
                setCountryName('');
            }
            if (stateId) {
                let stateResponse = await stateApi.getById(stateId);
                let newStateData = stateResponse.data.data.state;
                let newStateName = newStateData ? newStateData.state : '';
                setStateName(newStateName);
            } else {
                setStateName('');
            }

            if(municipalityId){
                let municipalityResponse = await municipalityApi.getById(municipalityId)
                setMunicipality(municipalityResponse);
                setCityName(municipalityResponse.city)
            }else{
                setMunicipality(null)
                setCityName('')
            }
        } catch (error) {
            console.error(error.message, error.stack);
        }
    };

    useEffect(()=>{
        getCountryStateAndMunicipalityById(countryId, stateId, municipalityId)
    },[countryId, stateId, municipalityId])

    const handleSelectItem = (pageNumber)=>{
        setPageItemActive(pageNumber);
    }

    const getPaginationItems =()=>{
        let items = [];
        for (let number = 1; number <= totalPages; number++) {
            items.push(
                <Pagination.Item key={number} active={number === pageItemActive} onClick={()=>handleSelectItem(number)}>
                    {number}
                </Pagination.Item>,
            );
        }
        return items;
    }

    const previousPage = async()=>{
        let currentPageNumber = pageItemActive > 1 ? pageItemActive - 1 : pageItemActive
        setPageItemActive(currentPageNumber);
    }

    const nextPage = async()=>{
        let currentPageNumber = pageItemActive < totalPages ? pageItemActive + 1 : totalPages
        setPageItemActive(currentPageNumber);
    }

    const handleSortByName = async()=>{
        let value = sortByName === 'ASC' ? 'DESC' : 'ASC'
        setSortByName(value)
        setSortById('')
        try {
            let response = await municipalityApi.sortOrganizationsByName(cityName, pageItemActive, value)
            setOrganizations([...response.organizations]);
            setTotalPages(response.total_pages)
        } catch (err) {
            console.error(err.message, err.stack);
        }
    }

    const handleSortById = async()=>{
        let value = sortById === 'ASC' ? 'DESC' : 'ASC'
        setSortById(value)
        setSortByName('')
        try {
            let response = await municipalityApi.sortOrganizationsById(cityName, pageItemActive, value)
            setOrganizations([...response.organizations]);
            setTotalPages(response.total_pages)
        } catch (err) {
            console.error(err.message, err.stack);
        }
    }

    const items = getPaginationItems()

    if(!municipality) {
        return (<Loading />)
    }
  
    return(
        <Page 
            buttonText="New Organization"
            buttonUrl={`/organization/new`}
            header={countryName && stateName && municipality && municipality.city ? `${countryName} - ${stateName} - ${municipality.city}` : ''}
        >
            {
                organizations.length > 0 ?
                    <div>
                        <OrganizationsTable organizations={organizations} handleSortByName={handleSortByName} handleSortById={handleSortById} sortByName={sortByName} sortById={sortById} />
                        {
                            totalPages > 1 && <div className="events-pagination-wrap">
                                <Pagination size="sm">
                                    <Pagination.Prev onClick={() => previousPage()} />
                                    {items}
                                    <Pagination.Next onClick={() => nextPage()} />
                                </Pagination>
                            </div>
                        }
                    </div>
                    :
                    <div className='dashboard-content-footer'>
                        <span className='empty-table'>No data</span>
                    </div>
            }
        </Page>
    )
}

export default MunicipalityPage;