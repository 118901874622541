import React, {useState} from 'react';
import Error from "../../fragments/form/Error"
import Message from "../../fragments/form/Message"
import TextValue from "../../fragments/table/TextValue"
import {Link} from "react-router-dom";
import arrowUp from "../../assets/icons2/icons8-sort-arrow-up-24.png";
import arrowDown from "../../assets/icons2/icons8-sort-arrow-down-24.png";
import "./styles.css"


function OrganizationsTable (props) {
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [isHovered, setIsHovered] = useState({ id: false, name: false }); 
    
    const handleMouseEnter = (key) => { 
        setIsHovered(prevState => ({ ...prevState, [key]: true })); 
    }; 
    const handleMouseLeave = (key) => { 
        setIsHovered(prevState => ({ ...prevState, [key]: false }));
     };

    return(
        <div>
            <Error message={error} />
            <Message message={message} />
            <table>
                <tbody>
                    <tr>
                        <th onClick={()=>props.handleSortById()} onMouseEnter={() => handleMouseEnter('id')} onMouseLeave={() => handleMouseLeave('id')}>
                            <div className="municility-sort-wrap">
                                ID
                                <button className="municility-sort-round-button" >
                                    {(props.sortById || isHovered.id) && (
                                        <button className="municility-sort-round-button">
                                            {props.sortById === 'DESC' ? <img src={arrowDown} alt="Arrow Down" width={18} height={18} />
                                                : <img src={arrowUp} alt="Arrow Up" width={18} height={18} />
                                            }
                                        </button>)}
                                </button>
                            </div>
                        </th>
                        <th onClick={()=>props.handleSortByName()} onMouseEnter={() => handleMouseEnter('name')} onMouseLeave={() => handleMouseLeave('name')}>
                            <div className="municility-sort-wrap" >
                                NAME
                                {(props.sortByName|| isHovered.name) && (
                                    <button className="municility-sort-round-button">
                                        {props.sortByName === 'DESC' ? <img src={arrowDown} alt="Arrow Down" width={18} height={18} />
                                            : <img src={arrowUp} alt="Arrow Up" width={18} height={18} />
                                        }
                                    </button>)}
                            </div>
                        </th>
                        <th>ACTIONS</th>
                    </tr>

                    {props.organizations.map((organization, index) => (
                        <tr key={index}>
                            <td><TextValue value={organization.id} /></td>
                            <td><TextValue value={organization.name} /></td>
                            <td>
                                <span>
                                    <Link to={`/organization/${organization.id}/edit`}>
                                        <i className="bi bi-pen">Edit</i>
                                    </Link>
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default OrganizationsTable;