import React, { useState, useEffect } from "react"
import OrganizationsApi from "../../services/organizations";
import Page from "../Page"
import OrganizationsTable from "../../tables/OrganizationsTable"
import DashboardHeader from '../../components/DashboardHeader';
import { PopupboxContainer } from 'react-popupbox';

function OrganizationsPage(props) {
    const [organizations, setOrganizations] = useState([]);
    const organizationsApi = new OrganizationsApi(props.token);
    const [search, setSearch] = useState('');
    const [searchCity, setSearchCity] = useState('');
    const [searchStatus, setSearchStatus] = useState('All');

    const fetch = () => {
        organizationsApi.search(searchStatus).then((response) => {
            setOrganizations(response);
        })
    }
    // useEffect(() => {
    //     fetch()
    // }, []);

    useEffect(()=>{
        if(search && searchCity){
            organizationsApi.searchByCity(searchCity, searchStatus).then((response) => {
                let filteredData = response.filter((org) => org.name === search)
                setOrganizations([...filteredData]);
            }).catch((error) => {
                console.error(error);
                setOrganizations([]);
            });
        }else if(search && !searchCity){
            let search_results = organizations.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));
            setOrganizations([...search_results]);
        }else if(!search && searchCity){
            organizationsApi.searchByCity(searchCity, searchStatus).then((response) => {
                setOrganizations(response);
            }).catch((error) => {
                console.error(error);
                setOrganizations([]);
            });
        }else{
            fetch()
        }
    },[search, searchCity, searchStatus])

    // Search
    const __handleSearch = (event) => {
        setSearch(event.target.value);
        // if (event.target.value !== '') {
        //     let search_results = organizations.filter((item) => item.name.toLowerCase().includes(event.target.value.toLowerCase())
        //     );
        //     setOrganizations([...search_results]);
        // }
        // else {
        //     fetch()
        // }
    };

    const handleSearchByCity =(event)=>{
        let value = event.target.value;
        setSearchCity(value);
    }

    const handleSearchByStatus =(event)=>{
        let value = event.target.value;
        setSearchStatus(value);
    }
  
    
    return (
        <div className='dashboard-content'>
            <DashboardHeader
                btnText="New Organization" link='/organization/new' />
            <div className='dashboard-content-container'>
                <div className='dashboard-content-header'>
                    <h2>Organizations</h2>
                    <div>
                        <div className='dashboard-content-search'>
                            <select                                
                                value={searchStatus}                                
                                className='dashboard-content-input search-status-input'
                                onChange={e => handleSearchByStatus(e)} >                                
                                <option value="All" selected="selected">All</option>    
                                <option value="Published">Published</option>    
                                <option value="Unpublished">Unpublished</option>
                            </select>
                        </div>
                        <div className='dashboard-content-search'>
                            <input
                                type='text'
                                value={search}
                                placeholder='Search by name..'
                                className='dashboard-content-input'
                                onChange={e => __handleSearch(e)} />
                        </div>
                        <div className='dashboard-content-search search-city-input'>
                            <input
                                type='text'
                                value={searchCity}
                                placeholder='Search by city..'
                                className='dashboard-content-input'
                                onChange={e => handleSearchByCity(e)} />
                        </div>
                    </div>
                </div>
                <div className="organizations-custom-popup">
                    <PopupboxContainer />
                </div>
                    {
                        organizations.length > 0 ?
                            <OrganizationsTable organizations={organizations} {...props} refresh={fetch} />
                            :
                            <div className='dashboard-content-footer'>
                                <span className='empty-table'>No data</span>
                            </div>
                    }
               
            </div>
        </div>
    )
}

export default OrganizationsPage