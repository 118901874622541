import TextPanelItem from "../fragments/panel/TextPanelItem"
import BoolPanelItem from "../fragments/panel/BoolPanelItem"
import ImagePanelItem from "../fragments/panel/ImagePanelItem"
import Panel from "../fragments/panel/Panel"

const EventPanel = (props) => (
    <Panel>
        <TextPanelItem value={props.event.id} label="ID" />
        <TextPanelItem value={props.event.title} label="Title" />
        <TextPanelItem value={props.event.description} label="Description" />
        <TextPanelItem value={props.event.start_time}  label="Start Time" />
        <TextPanelItem value={props.event.end_time}  label="End Time" />
        <TextPanelItem value={props.event.website}  label="Website" />
        <ImagePanelItem url={props.event.card_image.url} label="Card Image"  />
        <ImagePanelItem url={props.event.profile_image.url} label="Profile Image" />
        <TextPanelItem value={props.event.buy_ticket_url} label="Buy Ticket Url" />
        <BoolPanelItem value={props.event.is_published}  label="Published?" />
    </Panel>
)

export default EventPanel;