import React, {useState} from 'react';
import UnpublishedEventsApi from "../services/unpublishedEvents";
import Error from "../fragments/form/Error"
import Message from "../fragments/form/Message"

import TextValue from "../fragments/table/TextValue"
import ItemActions from "../fragments/table/ItemActions"

function UnpublishedEventsTable (props) {
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const unpublishedEventsApi = new UnpublishedEventsApi(props.token);

    function deleteEvent(id){
        unpublishedEventsApi.delete(id).then((response) => {
            setMessage(`Event with ID ${id} has been deleted`);
            setError("")
            props.refresh();
        })
        .catch((err) => {
            console.error(err.message);
            setError(err.message)
            setMessage("")
        });
    }
    
    return(
        <div>
            <Error message={error} />
            <Message message={message} />
            <table>
                <tbody>
                    <tr>
                        <th>ID</th>
                        <th>NAME</th>
                        <th>CITY</th>
                        <th>PROV/STATE</th>
                        <th>COUNTRY</th>
                        <th>STATUS</th>
                        <th>ACTIONS</th>
                    </tr>
                    {props.events.map((event, index) => (
                        <tr key={index}>
                            <td><TextValue value={event.id} /></td>
                            <td><TextValue value={event.title} /></td>
                            { event.location.city ? <td><TextValue value={event.location.city} /></td> : <td></td> }
                            { event.location.state ? <td><TextValue value={event.location.state} /></td> : <td></td> }
                            { event.location.country ? <td><TextValue value={event.location.country} /></td> : <td></td> }
                            <td><TextValue value={event.is_published ? 'Published' : 'Pending'} /></td>
                            <td><ItemActions
                                    onConfirm={() => deleteEvent(event.id, event.title)}
                                    editUrl={`/organization/${event.organization_id}/event/${event.id}/edit`}
                                    viewUrl={`/organization/${event.organization_id}/event/${event.id}`}
                                    itemName={`event "${event.title}"`}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default UnpublishedEventsTable;