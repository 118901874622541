
import FormGroup from "./FormGroup"
import {useState, useEffect} from 'react';

const TextField = (props) => {
    const [data, setData] = useState(props.value);
    const onChange = (e) => {
        setData(e.target.value);
        props.onChange(props.id, e.target.value);
    }

    useEffect(() => {
        if(props.onLoad) {
            props.onLoad();
        }
    },[])
  
    return (
        <FormGroup {...props}>
            <input required={props.required} className="form-control"
                type={props.type||"text"}
                name={props.id}
                onChange={onChange}
                value={data}
                step={props.step||"any"}
            />
        </FormGroup>
    )
}

export default TextField;