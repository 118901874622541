import {useState, useEffect} from 'react';

import DropdownSelector from "../fragments/form/DropdownSelector"
import ImageSelector from "../fragments/form/ImageSelector"
import TextField from "../fragments/form/TextField"
import TextAreaField from "../fragments/form/TextAreaField"
import DateTimeSelector from "../fragments/form/DateTimeSelector"
import CheckboxSelector from "../fragments/form/CheckboxSelector"
import SubmitButton from "../fragments/form/SubmitButton"


function CreateEventForm(props){
    const [formData, setFormData] = useState(new FormData());

    const onChange = (id, value) => {
        formData.set(id, value);
        setFormData(formData);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        props.onSubmit(formData);
    }
    
    return (
        <form onSubmit={onSubmit}>
            <DropdownSelector id="location_id" required={true} collection={props.locations} onChange={onChange} label="Location" prompt="Please select a location" />
            <DropdownSelector id="category_id" required={true}  collection={props.categories} onChange={onChange} label="Category" prompt="Please select a category" />
            <ImageSelector id="card_image" required={true}  onChange={onChange} label="Card Image" />
            <ImageSelector id="profile_image" required={true}  onChange={onChange} label="Profile Image" />
            <TextField id="title" required={true}  onChange={onChange} label="Title" />
            <TextAreaField id="description" required={true}  onChange={onChange} label="Description" rows="10" cols="20"/>
            <TextField id="website" required={false}  onChange={onChange} label="Website" />
            <TextField id="buy_ticket_url" required={false}  onChange={onChange} label="Buy Ticket Url" />
            <DateTimeSelector id="start_time" required={true}  onChange={onChange} label="Start Time" />
            <DateTimeSelector id="end_time" required={true}  onChange={onChange} label="End Time" />
            <CheckboxSelector id="is_published" onChange={onChange} label="Publish" />
            <SubmitButton />
        </form>
    )
}

export default CreateEventForm;