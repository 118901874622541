import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from "react-router-dom";

import EventsApi from "../../services/events";
import CategoryApi from "../../services/categories";
import LocationApi from "../../services/locations";
import OrganizationApi from "../../services/organizations";

import Error from "../../fragments/form/Error"
import Message from "../../fragments/form/Message"
import Loading from "../../fragments/Loading";
import EditEventForm from "../../forms/EditEventForm";
import Page from "../Page";

const EditOrganizationEventPage = (props) => {
    const [event, setEvent] = useState();
    const [error, setError] = useState();
    const [message, setMessage] = useState();
    const [locations, setLocations] = useState();
    const [categories, setCategories] = useState();
    const [organization, setOrganization] = useState();

    const eventsApi = new EventsApi(props.token);
    const categoryApi = new CategoryApi(props.token);
    const locationApi = new LocationApi(props.token);
    const organizationApi = new OrganizationApi(props.token);

    const { organizationId, eventId } = useParams();
    const navigate = useNavigate();
    
    useEffect(() => {
        categoryApi.all().then((categories) => {
            setCategories(Object.values(categories.filter(category => category.is_event)));
        })
        eventsApi.getById(eventId).then((response) => {
            setEvent(response)
        })
        locationApi.getByOrganizationId(organizationId).then((locations) => {
            setLocations(Object.values(locations));
        })
        organizationApi.getById(organizationId).then((response) => {
            setOrganization(response);
        })
    }, []);

    const onSubmit = (formData) => {
        eventsApi.update(eventId, formData).then((response) => {
            navigate(`/organization/${organizationId}/event/${eventId}`);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
            setError(err.message)
            setMessage("");
        });
    }


    if(!event || !organization || !locations || !categories) {
        return (<Loading />)
    }
    return (
        <Page
            buttonText="Back"
            buttonUrl={`/organization/${organizationId}/events`}
            header={`Edit Event: ${event.title}`}
        >
            <Error message={error} />
            <Message message={message} />
            <EditEventForm 
                {...props}
                event={event}
                locations={locations}
                categories={categories}
                organization={organization}
                onSubmit={onSubmit} />
        </Page>

    );
}

export default EditOrganizationEventPage;
