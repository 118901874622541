import React, {useState} from 'react';

import DropdownSelector from "../fragments/form/DropdownSelector"
import TextField from "../fragments/form/TextField"
import CheckboxSelector from "../fragments/form/CheckboxSelector"
import SubmitButton from "../fragments/form/SubmitButton"


const CreateLocationForm = (props) => {
    const [formData, setFormData] = useState({});

    const onChange = (id, value) => {
        formData[id] = value;
        if(id === 'country'){
            props.handleSetCountryId(value)
            let countryData = props.countries.find((e) => e.id === parseInt(value))
            let countryValue = countryData ? countryData.country : '';
            formData[id] = countryValue
        }
        if(id === 'state'){
            props.handleSetStateId(value) 
            let stateData = props.states.find((e) => e.id === parseInt(value))
            let stateValue = stateData ? stateData.state : '';
            formData[id] = stateValue
        }
        if(id === 'city'){
            let cityData = props.cities.find((e) => e.id === parseInt(value))
            let cityValue = cityData ? cityData.city : '';
            formData[id] = cityValue
        }
        setFormData(formData);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        props.onSubmit(formData);
    }

    return(
        <div>
            <form onSubmit={onSubmit}>
                <TextField
                    id="name"
                    onChange={onChange}
                    label="Name"
                    required={true} 
                    onLoad={() => onChange("name", "")} 
                />
                <TextField
                    id="address_1"
                    onChange={onChange}
                    label="Address1"
                    required={true} 
                    onLoad={() => onChange("address_1", "")} 
                />
                <TextField
                    id="address_2"
                    onChange={onChange}
                    label="Address2"
                    required={false}
                    onLoad={() => onChange("address_2", "")} 
                />
                <DropdownSelector id="country" required={true} collection={props.countries} onChange={onChange} label="Country" prompt="Please select a country"  onLoad={() => onChange("country", '')} />
                <DropdownSelector id="state"  required={true} collection={props.states} onChange={onChange} label="State" prompt="Please select a country before select a state"  onLoad={() => onChange("state", '')} />
                <DropdownSelector id="city"  required={true} collection={props.cities} onChange={onChange} label="City" prompt="Please select a state before select a city"  onLoad={() => onChange("city", '')} />
                {/* <TextField
                    id="city"
                    onChange={onChange}
                    label="City"
                    required={true} 
                    onLoad={() => onChange("city", "")} 
                /> */}
                {/* <TextField
                    id="state"
                    onChange={onChange}
                    label="State"
                    required={true} 
                    onLoad={() => onChange("state", "")} 
                /> */}
                <TextField
                    id="zip"
                    onChange={onChange}
                    label="Zip"
                    required={true} 
                    onLoad={() => onChange("zip", "")} 
                />
                {/* <TextField
                    id="country"
                    onChange={onChange}
                    label="Country"
                    required={true} 
                    onLoad={() => onChange("country", "")} 
                /> */}
                <TextField
                    id="longitude"
                    onChange={onChange}
                    label="Longitude"
                    required={true} 
                    onLoad={() => onChange("longitude", "")} 
                />
                <TextField
                    id="latitude"
                    onChange={onChange}
                    label="Latitude"
                    required={true} 
                    onLoad={() => onChange("latitude", "")} 
                />
                <CheckboxSelector
                    id="is_published"
                    onChange={onChange}
                    label="Publish"
                    onLoad={() => onChange("is_published", "0")}
                />
                <SubmitButton />
            </form>
        </div>
    )
}

export default CreateLocationForm;