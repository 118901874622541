import React, { useState, useEffect } from "react"
import UnpublishedEventsApi from "../../../services/unpublishedEvents";
import Page from "../../Page"
import UnpublishedEventsTable from "../../../tables/UnpublishedEventsTable"
// import DashboardHeader from '../../../components/DashboardHeader';
import { PopupboxContainer } from 'react-popupbox';
import Pagination from 'react-bootstrap/Pagination';

function OrganizationsPage(props) {
    const [events, setEvents] = useState([]);
    const unpublishedEventsApi = new UnpublishedEventsApi(props.token);
    const [search, setSearch] = useState('');
    const [searchCity, setSearchCity] = useState('');
    const [totalPages, setTotalPages] = useState(0);
    const [perPageItems, setPerPageItems] = useState(0)
    const [pageItemActive, setPageItemActive] = useState(1);

    const fetch = () => {
        unpublishedEventsApi.all("Unpublished", pageItemActive).then((response) => {
            setEvents(response.events);
            setTotalPages(response.totalPages)
            setPerPageItems(response.perPageItems)
        })
    }
  
    useEffect(()=>{
        if(search && searchCity){
            unpublishedEventsApi.searchByCity(searchCity, "Unpublished", pageItemActive).then((response) => {
                let filteredData = response.events.filter((event) => event.title === search)
                setEvents([...filteredData]);
                setTotalPages(Math.ceil(filteredData.length / response.perPageItems));
                setPerPageItems(response.perPageItems)
            }).catch((error) => {
                console.error(error);
                setEvents([]);
            });
        }else if(search && !searchCity){
            unpublishedEventsApi.search(search, "Unpublished", pageItemActive).then((response) => {
                setEvents(response.events);
                setTotalPages(response.totalPages)
                setPerPageItems(response.perPageItems)
            }).catch((error) => {
                console.error(error);
                setEvents([]);
            });
        }else if(!search && searchCity){
            unpublishedEventsApi.searchByCity(searchCity, "Unpublished", pageItemActive).then((response) => {
                setEvents(response.events);
                setTotalPages(response.totalPages)
                setPerPageItems(response.perPageItems)
            }).catch((error) => {
                console.error(error);
                setEvents([]);
            });
        }else{
            fetch()
        }
    },[search, searchCity, pageItemActive])

    // Search
    const __handleSearch = (event) => {
        setSearch(event.target.value);
    };

    const handleSearchByCity =(event)=>{
        let value = event.target.value;
        setSearchCity(value);
    }

    const handleSelectItem = async (pageNumber )=>{
      setPageItemActive(pageNumber);
    }

    const getPaginationItems =()=>{
      let items = [];
      for (let number = 1; number <= totalPages; number++) {
          items.push(
              <Pagination.Item key={number} active={number === pageItemActive} onClick={()=>handleSelectItem(number)}>
                  {number}
              </Pagination.Item>,
          );
      }
      return items;
  }

  const previousPage = async()=>{
      let currentPageNumber = pageItemActive > 1 ? pageItemActive - 1 : pageItemActive
      setPageItemActive(currentPageNumber);
  }

  const nextPage = async()=>{
      let currentPageNumber = pageItemActive < totalPages ? pageItemActive + 1 : totalPages
      setPageItemActive(currentPageNumber);
  }

  const items = getPaginationItems()
 
    return (
        <div className='dashboard-content'>
            {/* <DashboardHeader
                btnText="New Organization" link='/organization/new' /> */}
            <div className='dashboard-content-container'>
                <div className='dashboard-content-header'>
                    <h2>Unpublished Events</h2>
                    <div>
                        <div className='dashboard-content-search'>
                            <input
                                type='text'
                                value={search}
                                placeholder='Search by name..'
                                className='dashboard-content-input'
                                onChange={e => __handleSearch(e)} />
                        </div>
                        <div className='dashboard-content-search search-city-input'>
                            <input
                                type='text'
                                value={searchCity}
                                placeholder='Search by city..'
                                className='dashboard-content-input'
                                onChange={e => handleSearchByCity(e)} />
                        </div>
                    </div>
                </div>
                <div className="organizations-custom-popup">
                    <PopupboxContainer />
                </div>
                    {
                        events.length > 0 ?
                            <UnpublishedEventsTable events={events} {...props} refresh={fetch} />
                            :
                            <div className='dashboard-content-footer'>
                                <span className='empty-table'>No data</span>
                            </div>
                    }
                {
                totalPages > 1 && <div className="events-pagination-wrap">
                    <Pagination size="sm">
                        <Pagination.Prev onClick={()=>previousPage()}/>
                        {items}
                        <Pagination.Next onClick={()=>nextPage()}/>
                    </Pagination>
                </div>
            }
            </div>
        </div>
    )
}

export default OrganizationsPage