import React, {useState, useEffect} from "react"
import {useParams, useNavigate} from "react-router-dom";

import OrganizationApi from "../../services/organizations";
import CategoryApi from "../../services/categories";
import LocationApi from "../../services/locations";
import CountryApi from "../../services/countries";
import StateApi from "../../services/states";
import MunicipalityApi from "../../services/municipalities";
import OrganizationHeaderNav from "../../fragments/nav/OrganizationHeaderNav"
import CreateLocationForm from "../../forms/CreateLocationForm"
import Page from "../Page"
import Loading from "../../fragments/Loading"
import Error from "../../fragments/form/Error"
import Message from "../../fragments/form/Message"

const CreateOrganizationLocationPage = (props) => {
    const [error, setError] = useState();
    const [message, setMessage] = useState();
    const [organization, setOrganization] = useState();
    const [categories, setCategories] = useState();
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([])
    const [countryId, setCountryId] = useState(0);
    const [stateId, setStateId] = useState();

    const locationApi = new LocationApi(props.token);
    const organizationApi = new OrganizationApi(props.token);
    const categoryApi = new CategoryApi(props.token);
    const countryApi = new CountryApi(props.token);
    const stateApi = new StateApi(props.token);
    const municipalityApi = new MunicipalityApi(props.token);

    const { organizationId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        organizationApi.getById(organizationId).then((response) => {
            setOrganization(response);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });

        categoryApi.all().then((categories) => {
            setCategories(Object.values(categories));
        })
        .catch((err) => {
            console.error(err.message);
        });

        countryApi.getlist(100).then((response) => {
            setCountries([...response]);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
        });

    },[]);

    const fetchStatesByCountryId = async(countryId)=>{
        try {
            if(countryId){
                let states = await stateApi.getlist(countryId, 100)
                setStates([...states])
            }else{
                setStates([])
            }
        } catch (err) {
            console.error(err.message);
        }
    }

    const fetchCitiesByStateId = async(stateId)=>{
        try {
            if(stateId){
                let cities = await municipalityApi.getlist(stateId, 100)
                setCities([...cities])
            }else{
                setStates([])
            }
        } catch (err) {
            console.error(err.message);
        }
    }

    useEffect(()=>{
        fetchStatesByCountryId(countryId)
        fetchCitiesByStateId(stateId)
    },[countryId, stateId])

    const handleSetCountryId=(id)=>{
        setCountryId(id)
    }

    const handleSetStateId=(id)=>{
        setStateId(id)
    }
    
    const onSubmit = (formData) => {
        formData["organization_id"] = organizationId;
        locationApi.create(formData).then((response) => {
            navigate(`/organization/${organizationId}/location/${response.id}`);
        })
        .catch((err) => {
            console.error(err.message, err.stack);
            setError(err.message)
            setMessage("");
        });
    }

    if(!organization || !categories) {
        return (<Loading />)
    }

    return (
        <Page 
            buttonText="Back"
            buttonUrl={`/organization/${organizationId}/locations`}
            header={`Creating focation for organization: ${organization.name}`}
        >
            <OrganizationHeaderNav organizationId={organizationId} />
            <Error message={error} />
            <Message message={message} />
            <CreateLocationForm
                organization={organization}
                categories={categories}
                countries={countries}
                states={states}
                cities={cities}
                handleSetCountryId={handleSetCountryId}
                handleSetStateId={handleSetStateId}
                onSubmit={onSubmit}
                {...props} />
        </Page>
    )
}

export default CreateOrganizationLocationPage;